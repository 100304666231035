/**
* @file
* 01-atoms/text/tagline/_tagline.scss
*/

.tagline {
  display: block;
  margin-left: $standard-spacing;
  margin-bottom: $standard-spacing;
  color: $dark-blue;
  font-style: italic;
}

h1 {
  + .tagline {
    font-size: $h2-font-size--smaller;
  }
}

h2 {
  + .tagline {
    font-size: $h3-font-size--smaller;
  }
}

h3 {
  + .tagline {
    font-size: $h4-font-size--smaller;
  }
}

h4 {
  + .tagline {
    font-size: $h5-font-size--smaller;
  }
}

h5 {
  + .tagline {
    font-size: $h6-font-size--smaller;
  }
}

.black-bg {
  h1, h2, h3,
  h4, h5, h6 {

    + .tagline {
      color: $lightest-green;
    }
  }
}

.dark-blue-bg {
  h1, h2, h3,
  h4, h5, h6 {

    + .tagline {
      color: $light-green;
    }
  }
}

.blue-bg, .gray-bg {
  h1, h2, h3,
  h4, h5, h6 {

    + .tagline {
      color: $white;
    }
  }
}
