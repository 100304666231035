/**
* @file
* 02-molecules/charts/donut-chart/_donut-chart.scss
*/

$chart-size: 300px;
$chart-blues: (#429ad2,#003f87,#7ab4da,#0060ab,#afcee1,#007ac8,#e3e7e9,#002f5e);
$chart-light-blues: (#006ebb,#57b8f7,#1681ca,#7ecafa,#2c93d9,#b3d9f1,#42a6e8,#e3e7e9);
$chart-greens: (#006b3f,#c2dc2c,#358a30,#d3e570,#6aa921,#e4edb3,#9fc812,#f5f6f7);

.donut-chart {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .block__title {
    flex: 0 0 100%;
  }

  svg {
    width: $chart-size;
    height: $chart-size;
    transform: rotate(-90deg);
    background: transparent;
    border-radius: 50%;
    margin: $double-spacing;
  }

  circle {
    fill: transparent;
    stroke-width: 15.915;

    @for $i from 1 through length($chart-blues) {
      &:nth-of-type(#{$i}) {
        stroke: nth($chart-blues, $i);
      }
    }
  }

  .data {
    li {
      list-style: none;

      &:before {
        position: relative;
        top: 3px;
        display: inline-block;
        width: $double-spacing;
        height: $double-spacing;
        margin-right: $half-spacing;
        content: "";
      }

      @for $i from 1 through length($chart-blues) {
        &:nth-of-type(#{$i}) {
          &:before {
            background: nth($chart-blues, $i);
          }
        }
      }
    }
  }

  &.green{
    circle {
      @for $i from 1 through length($chart-greens) {
        &:nth-of-type(#{$i}) {
          stroke: nth($chart-greens, $i);
        }
      }
    }

    .data {
      li {
        @for $i from 1 through length($chart-greens) {
          &:nth-of-type(#{$i}) {
            &:before {
              background: nth($chart-greens, $i);
            }
          }
        }
      }
    }
  }

  &.light-blue{
    circle {
      @for $i from 1 through length($chart-light-blues) {
        &:nth-of-type(#{$i}) {
          stroke: nth($chart-light-blues, $i);
        }
      }
    }

    .data {
      li {
        @for $i from 1 through length($chart-light-blues) {
          &:nth-of-type(#{$i}) {
            &:before {
              background: nth($chart-light-blues, $i);
            }
          }
        }
      }
    }
  }

  &.pie {
    circle {
      fill: transparent;
      stroke-width: 31.83;
    }
  }
}
