/**
* @file
* 01-atoms/images/image-background/_image-background.scss
*/

.background-image,
.background-image > div {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  object-fit: cover;

  img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
