/**
* @file
* 02-molecules/banner/_banner.scss
*/
.block--banner {
  position: relative;
  display: grid;
  align-content: center;
  padding: $triple-spacing;
  text-align: center;

  .body {
    position: relative;
    z-index: 1;
    max-width: $max-content-width;
    margin: auto;
  }

  &.blue-overlay {
    a:not(.button) {
      color: $white;
    }
  }

  &.dark-blue-overlay, &.black-overlay {
    a:not(.button) {
      color: scale-color($light-blue, $lightness: 48%);
    }
  }

  &.blue-overlay, &.dark-blue-overlay,
  &.black-overlay {
    a:not(.button) {
      &:hover, &:focus, &:active {
        color: $dark-blue;
      }

      &:focus {
        outline-color: $gray;
      }
    }
  }


  &.gray-overlay {
    a:not(.button) {
      color: $dark-blue;

      &:focus {
        outline-color: inherit;
      }
    }
  }
}

/* Retrofit for existing pages with beyond basic block banners */
.block--half-page,
.block--full-page {
  h1,
  h2 {
    z-index: 1;
  }
}
