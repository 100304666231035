/**
* @file
* molecules/messaging/messages/_messages.scss
*/

.messages {
  ul {
    margin-bottom: 0;
  }
}
.messages--status,
.messages--warning,
.messages--error {
  display: block;
  margin-bottom: $half-spacing;
  padding: $half-spacing;
  text-align: center;
}

.messages--status {
  @include message($blue);
}
.messages--warning {
  @include message($yellow);
}
.messages--error {
  @include message($red);
}

.node--unpublished {
  border-top: solid $border-width $warning-color;

  &:before {
    @include message($warning-color);
    position: absolute;
    right: 0;
    content: "Unpublished";
  }
}
