/**
 * @file
 * molecules/navigation/breadcrumb/_breadcrumb.scss
 */

.breadcrumb {
  margin-top: $standard-spacing;
  font-size: $p-font-size--smaller;

  ol {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      margin-right: $half-spacing;

      &:before {
        margin-right: $half-spacing;
      }
    }
  }
  li + li::before {
    content: '>';
  }
}
