/**
 * @file
 * molecules/links/link-list/_link-list.scss
 */

.link-list {
  @include breakpoint($medium) {
    padding: $standard-spacing;
    display: flex;

    h1, h2, h3, h4, h5, h6 {
      &.col {
        margin: -($standard-spacing) auto;
      }
    }
  }
  padding: 0 $standard-spacing;
}

.col {
  &.left {
    @include breakpoint($medium) {
      flex: 1;
    }
  }

  &.right {
    @include breakpoint($medium) {
      flex: 2;
    }
  }
  padding: $half-spacing;
}
