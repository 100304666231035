/**
* @file
* 02-molecules/content/accordion.scss
*/
$left-col-width: 84px;
$accordion-border: $dark-blue solid $border-width--thicker;

.accordion-set {
  margin-bottom: $standard-spacing;
  .item {
    border-top: $accordion-border;
    border-right: $accordion-border;
    border-left: $accordion-border;

    &:last-of-type {
      border-bottom: $accordion-border;
    }

    .field__title,
    .title {
      margin: 0;

      .expand {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 0;
        padding: $standard-spacing;
        text-align: left;
        color: $darkest-blue;
        border: $border-width solid transparent;
        border-radius: 0;
        background: none;
        font-size: $p-font-size;
        font-weight: $font-weight--semibold;
        line-height: 1.2em;
        box-shadow: none;

        &:active {
          transform: none;
        }

        &:focus {
          border: $border-width dotted $dark-blue;
          background-color: transparentize($dark-blue, 0.9);
          box-shadow: none;
        }

        .material-icons {
          color: $dark-blue;
          font-size: $icon-size--medium;
          margin-right: $standard-spacing;
        }
      }

      & + .content {
        display: none;
        padding: $standard-spacing;
        padding-top: 0;
        word-wrap: break-word;
      }
    }
  }
}

/* Make block editor widget a little more tolerable for accordion blocks */
#drupal-off-canvas .field--name-field-accordion-item tr.draggable {
  display: flex;
}

/* VU Accordion Styles */
.quick-accordion {
  .ui-accordion-content {
    height: auto;

    // Affects VU generated content
    .view {
      .view-content {
        .views-row {
          article {
            header {
              h2 {
                font-size: 25px;
              }

              p {
                font-size: $p-font-size;
              }
            }
          }
        }
      }
    }
  }

  // Affects RSS feed
  ul {
    p {
      font-size: $p-font-size
    }

    a {
      font-size: $p-font-size
    }
  }
}
