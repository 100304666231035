/**
 * @file
 * molecules/forms/form/_form--search-block.scss
 */

.form--search-block {
  input[type="search"] {
  }

  input[type="submit"] {
  }
}
