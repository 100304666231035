/*
 * @file
 * templates/layouts/threecol_25_50_25/_layout--threecol-25-50-25.scss
 */

.layout--threecol-25-50-25 {
  display: flex;
  flex-wrap: wrap;
}

.layout--threecol-25-50-25 > .layout__region,
.layout--threecol-25-50-25 > .layout__region--second {
  flex: 0 1 100%;
}

.layout--threecol-25-50-25 > .layout__region--first {
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 25%;
    max-width: 25%;
    padding-right: $standard-spacing;
  }
}

.layout--threecol-25-50-25 > .layout__region--second {
  @include breakpoint($medium) {
    flex: 0 1 50%;
    max-width: 50%;
    padding: 0 $standard-spacing;
  }
}

.layout--threecol-25-50-25 > .layout__region--third {
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 25%;
    max-width: 25%;
    padding-left: $standard-spacing;
  }
}
