/**
* @file
* 02-molecules/buttons/image-buttons/_image-buttons.scss
*/
$image-button-size: 200px;
$image-button-size--medium: 250px;
$image-button-size--large: 300px;

.image-button {
  @include breakpoint($medium) {
    width: $image-button-size--medium;
    height: $image-button-size--medium;
    margin: $image-button-size--medium / 4;
    margin-bottom: ($image-button-size--medium / 4) + $double-spacing;
  }
  @include breakpoint($large) {
    width: $image-button-size--large;
    height: $image-button-size--large;
    margin-right: 0;
    margin-left: 0;
  }
  position: relative;
  width: $image-button-size;
  height: $image-button-size;
  margin: $image-button-size / 4.5;
  transition: all 0.3s cubic-bezier(0.25,0.8,0.25,1);
  border-radius: 50%;
  box-shadow: $unfocus-shadow;


  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    content: "";
    transition: 0.5s ease all;
    border-radius: 50%;
  }

  &:before {
    width: 70%;
    height: 70%;
    transform: translate(-20%, -10%);
    background: transparentize($blue, 0.8);
  }

  &:after {
    width: 105%;
    height: 105%;
    transform: translate(15%, 10%);
    background: transparentize($blue, 0.9);
  }

  &:hover,
  &:focus-within {
    box-shadow: $focus-shadow;
  }


  &:hover:before,
  &:focus-within:before {
    transform: translate(60%, 45%);
  }
  &:hover:after,
  &:focus-within:after {
    transform: translate(-20%, -10%);
  }

  .image {
    background-color: $blue;
  }

  a {
    @include breakpoint($medium) {
      font-size: $image-button-size--medium / 8;
    }
    @include breakpoint($large) {
      font-size: $image-button-size--large / 8;
    }
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: $white;
    border-radius: 50%;
    background: transparentize($dark-blue, 0.5);
    font-size: $image-button-size / 8;
    font-weight: bold;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      background: transparentize($dark-blue, 0.1);
    }
  }
}

/* Multiple image buttons in a single container */
.block--image-button {
  &:nth-of-type(2) {
    .image-button{
      &:before {
        width: 100%;
        height: 100%;
        transform: translate(4%, 20%);
      }
      &:after {
        width: 80%;
        height: 80%;
        transform: translate(20%, -13%);
      }

      &:hover:before,
      &:focus-within:before {
        width: 100%;
        height: 100%;
        transform: translate(10%, -18%);
      }

      &:hover:after,
      &:focus-within:after {
        width: 80%;
        height: 80%;
        transform: translate(0%, 40%);
      }
    }
  }
  &:nth-of-type(3) {
    .image-button {
      &:before {
        width: 110%;
        height: 110%;
        transform: translate(12%, -9%);
      }
      &:after {
        width: 80%;
        height: 80%;
        transform: translate(-12%, 30%);
      }

      &:hover:before,
      &:focus-within:before {
        transform: translate(-12%, -20%);
      }

      &:hover:after,
      &:focus-within:after {
        transform: translate(50%, 14%);
      }
    }
  }
}

/* Simplified image button, no fancy overlaps */
.image-button--simple {
  .image-button {
    &:before,
    &:after {
      display: none;
    }
  }
}

/* Internet Explorer Fix */
@media screen and (min-width:0\0) {
  .layout--flex .layout__region .block--image-button {
    margin: 0 ($double-spacing * 2);
  }
}
