/**
 * @file
 * 00-utilities/_strip-unit.scss
 */

// Remove the unit of a length.
//   @param {Number} $number - Number to remove unit from.
//   @return {Number} - Unitless number.
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
