/**
* @file
* 01-atoms/01-text/_asides.scss
*/

aside,
.block--beyond-basics-block,
.paragraph--type--majors-aside {
  margin: $standard-spacing 0;

  &.dark-blue-bg,
  &.blue-bg,
  &.light-gray-bg,
  &.lightest-gray-bg,
  &.light-blue-bg,
  &.lightest-blue-bg {
    padding: $double-spacing;

    .block__title {
      margin-top: 0;
      padding-bottom: 0;
    }
  }
}
