/*
* @file
* templates/layouts/twcol/_twocol.scss
*/

.layout--twocol-section {
  display: flex;
  flex-wrap: wrap;

  > .layout__region {
    flex: 0 1 100%;
  }

  > .layout__region--first {
    @include breakpoint($small) {
      padding-right: $half-spacing;
    }
    @include breakpoint($medium) {
      padding-right: $standard-spacing;
    }
  }
  > .layout__region--second {
    @include breakpoint($small) {
      padding-left: $half-spacing;
    }
    @include breakpoint($medium) {
      padding-left: $standard-spacing;
    }
  }

  &.layout--twocol-section--50-50 > .layout__region--first,
  &.layout--twocol-section--50-50 > .layout__region--second {
    @include breakpoint($small) {
      flex: 0 1 50%;
    }
  }

  &.layout--twocol-section--33-67 > .layout__region--first,
  &.layout--twocol-section--67-33 > .layout__region--second {
    @include font-size--smaller;
    @include breakpoint($small) {
      flex: 0 1 33%;
    }
  }

  &.layout--twocol-section--33-67 > .layout__region--second,
  &.layout--twocol-section--67-33 > .layout__region--first {
    @include breakpoint($small) {
      flex: 0 1 67%;
    }
  }

  &.layout--twocol-section--25-75 > .layout__region--first,
  &.layout--twocol-section--75-25 > .layout__region--second {
    @include font-size--smaller;
    @include breakpoint($small) {
      flex: 0 1 25%;
    }
  }

  &.layout--twocol-section--25-75 > .layout__region--second,
  &.layout--twocol-section--75-25 > .layout__region--first {
    @include breakpoint($small) {
      flex: 0 1 75%;
    }
  }
}

.layout--twocol--page-width {
  @include breakpoint($large) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  margin-left: -$standard-spacing;
  margin-right: -$standard-spacing;

  > .layout__region {
    flex: 0 1 100%;
    max-width: none;
  }

  > .layout__region--first,
  > .layout__region--second {
    @include breakpoint($small) {
      flex: 0 1 50%;
    }
  }
}
