/**
 * @file
 * molecules/links/skip-content/_skip-content.scss
 */

.skip-link {
  &.focusable {
    &:focus {
      position: absolute !important;
      z-index: 3;
      top: 0;
      left: $standard-spacing;
      clip: initial;
      width: inherit;
      height: inherit;
      padding: $standard-spacing;
      color: $black;
      outline-color: $white;
      background: $light-green;
    }
  }
}
