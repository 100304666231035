/**
* @file
* organisms/views/grid-format/_grid-format.scss
* Flex styles for Drupal Views using the Grid Format
*/

.views-view-grid.horizontal .views-row,
.views-view-grid.vertical {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.views-view-grid.vertical .views-row,
.views-view-grid.horizontal .views-col{
  margin-bottom: $standard-spacing;
}

.views-view-grid .views-col {
  margin-right: $standard-spacing;
}

.views-view-grid .views-col:last-of-type {
  margin-right: 0;
}

@include breakpoint($small) {
  .views-view-grid.cols-1 .views-col {
    flex: 0 1 calc(100% - #{$standard-spacing});
  }

  .views-view-grid.cols-2 .views-col {
    flex: 0 1 calc(50% - #{$standard-spacing});
  }

  .views-view-grid.cols-3 .views-col {
    flex: 0 1 calc(33.333% - #{$standard-spacing});

  }
  .views-view-grid.cols-4 .views-col {
    flex: 0 1 calc(25% - #{$standard-spacing});
  }

  .views-view-grid.cols-5 .views-col {
    flex: 0 1 calc(20% - #{$standard-spacing});
  }
}

@include breakpoint($medium) {
  .views-view-grid.cols-6 .views-col {
    flex: 0 1 calc(16.666% - #{$standard-spacing});
  }

  .views-view-grid.cols-7 .views-col {
    flex: 0 1 calc(14.285% - #{$standard-spacing});
  }

  .views-view-grid.cols-8 .views-col {
    flex: 0 1 calc(12.5% - #{$standard-spacing});
  }

  .views-view-grid.cols-9 .views-col {
    flex: 0 1 calc(11.111% - #{$standard-spacing});
  }

  .views-view-grid.cols-10 .views-col {
    flex: 0 1 calc(10% - #{$standard-spacing});
  }
}
