/**
 * @file
 * templates/layouts/majors/_layout--majors.scss
 */

.layout--majors {
  display: flex;
  flex-wrap: wrap;


  > .layout__region {
    flex: 0 1 100%;
  }

  > .layout__region--sample-jobs {
    @include font-size--smaller;
    @include breakpoint($medium) {
      flex: 0 1 25%;
      max-width: 25%;
      padding: $double-spacing $standard-spacing;
    }
    margin-top: $double-spacing;
    padding: $standard-spacing $half-spacing;
    background-color: $blue;

    h2 {
      border-bottom-color: $lightest-green;
      font-size: $h4-font-size;
    }

    ul {
      padding-right: $standard-spacing;
    }
  }

  > .right-side-container {
    @include breakpoint($medium) {
      flex: 0 1 75%;
      max-width: 75%;
      padding-right: $double-spacing;
    }
  }

  > .layout__region--catalog {
    @include font-size--larger;
  }

  > .layout__region--related-majors {
    @include breakpoint($medium) {
      flex: 0 1 50%;
      max-width: 50%;
      padding-right: $standard-spacing;
    }
    margin-top: $double-spacing;
  }

  > .layout__region--department {
    @include breakpoint($medium) {
      flex: 0 1 50%;
      max-width: 50%;
      padding-left: $standard-spacing;
    }
    margin-top: $double-spacing;
  }
}

.block--nodemajortitle {
  display: inline-block;
}

.block--nodemajorfield-degree {
  display: inline-block;

  div {
    display: inline;
  }
}
