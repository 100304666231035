/**
* @file
* molecules/forms/user-login/_user-login.scss
* Additional styles for the user login form
*/

$max-box-width: 100px;

.user-login-form {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(auto-fit, minmax($max-box-width, 1fr));
  grid-template-areas:
    "login manual . ."
    "username username . ."
    "password password  . ."
    "submit submit  . .";

  grid-column-gap: $double-spacing;
  grid-row-gap: $double-spacing;
  justify-content: center;

  -ms-grid-rows: auto auto auto auto;
  -ms-grid-columns: 2fr $double-spacing 1fr $double-spacing 2fr;

  .manual-login {
    grid-area: manual;

    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }

  .cas-login-link {
    margin: 0;
    @extend
      .button,
      .dark-blue,
      .square;
    grid-area: login;

    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
  .cancel-cas {
    grid-area: cancel;

    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }

  .textfield {
    grid-area: username;

    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
  .password {
    grid-area: password;

    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
  }
  .form-actions {
    grid-area: submit;
    justify-self: start;

    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
  }

  .textfield,
  .password,
  .form-actions {
    display: none;
  }
}
