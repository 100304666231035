/*--- Menu Alert Styles for inaccessible accordion ---*/

.disable-menu-alert, .enable-menu-alert {
  display: block;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  position: absolute;
  height: 1px;
  width: 1px;


  &:focus-within {
    width: 100%;
    height: auto;
    position: relative;
    border: 2px solid transparentize($yellow, .8);
    background-color: transparentize($yellow, .8);
    clip: auto;
    overflow: visible;
    margin-bottom: 2em;
    padding: 1% 8% 1%;
  }

  .menu-enabled, .menu-disabled {
    &:focus {
      outline: 0;
    }

    h2 {
      font-size: 1.2em;

      > span {
      &.material-icons {
          font-size: inherit;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  a {
    color: $dark-blue;
    border: 2px solid transparent;
    padding: 0 2%;
  }
}

.disable-menu-alert, .enable-menu-alert {
  button {
    margin-bottom: 8%;
  }
}
