/**
* @file
* 03-organisms/navigation/ulti-menu/_ulti-menu.scss
*/

/* Overrides for ultimenu styles & functionality */
.ultimenu {
  overflow: visible;

  section.ultimenu__flyout {
    @include breakpoint($medium) {
      z-index: 0;
      top: 100%;
      right: 0 !important;
      display: none !important;
      transform: translatey(-($toggle-size/2));
    }
    @include breakpoint($large) {
      transform: translatey(-($toggle-size--larger/2));
    }
    display: block;
    visibility: hidden;
    overflow: hidden;
    max-height: 0;
    margin: 0;
    padding: 0;
    transition: $medium-transition-speed $standard-ease;
    opacity: 0;
    border-radius: 0;
    box-shadow: none;

    grid-area: flyout;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;

    &.open {
      @include breakpoint($medium) {
        position: absolute;
        display: block !important;
      }
      visibility: visible;
      max-height: 200vh;
      opacity: 1;
    }
  }

  .ultimenu__item {
    @include breakpoint($medium) {
      position: static;
      display: block;
      min-width: initial;

      &:hover {
        & > .ultimenu__flyout {
          display: none;
          visibility: hidden;
          opacity: 0;
        }

        & > .ultimenu__flyout.open {
          display: block;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    position: relative;
    display: grid;
    margin: 0;

    -ms-grid-columns: 1fr $toggle-size;
    -ms-grid-rows: auto auto;
    grid-template-areas: "link     icon"
    "flyout flyout";
    grid-template-columns: 1fr $toggle-size;
    grid-template-rows: auto auto;


    .ultimenu__link {
      z-index: 1;
      transition: $medium-transition-speed $standard-ease;

      -ms-grid-column: 1;
      grid-column: 1 / span 2;
      -ms-grid-column-span: 2;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;

        + .expand-icon {
          color: $black;
          background-color: $light-green;
        }
      }
    }

    &.has-ultimenu {
      .ultimenu__link {
        grid-area: link;
      }
    }

    .expand-icon {
      @include breakpoint($medium) {
        display: none;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transition: $medium-transition-speed $standard-ease;
      color: $white;
      border: none;

      grid-area: icon;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
    }
  }
}

.ultimenu--basic {
  .region {
    padding: 0;
  }
}

.ultimenu--horizontal {
  .ultimenu__link {
    &.is-active {
      background: $dark-blue;

      &.open {
        color: $dark-blue;
        background: $light-green;
      }
    }
    .caret {
      display: none;
    }
  }
}

.ultimenu--hover li:hover > .ultimenu__flyout,
.ultimenu--hover li a:active + .ultimenu__flyout,
.ultimenu--hover li a:focus + .ultimenu__flyout,
.ultimenu--hover li .ultimenu__flyout:focus-within {
  padding: 0;
}

.open {
  visibility: visible;
  opacity: 1;
}

/* Prevent a region in Drupal from getting position relative, which pushes menu below header area */
#block-ultimenumainnavigation {
  &.contextual-region {
    position: inherit;
  }
}

/* Hide the default ultimenu mobile menu toggle */
.button {
  &.button--ultimenu {
    display: none;
  }
}
