/**
 * @file
 * molecules/text/section-title/_section-title.scss
 */

.section-title {
  font-size: $h4-font-size;
  font-weight: $font-weight--black;
  &:after {
    display: none;
  }
}
