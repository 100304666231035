/**
 * @file
 * 02-molecules/navigation/local-tasks/_local-tasks.scss
 */
.block--local-tasks-block {
  overflow: scroll;
  max-width: calc(100vw - #{$double-spacing});
  margin-bottom: $standard-spacing;
  font-size: $p-font-size--smaller;

  ul:not(.contextual-links) {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-bottom: solid $border-width--thicker $gray;

    &:nth-of-type(2) {
      margin-top: $half-spacing;
      margin-left: $standard-spacing;
      border-bottom-color: $light-gray;
    }

    li {
      margin-bottom: 0;
    }

    a {
      display: block;
      padding: $half-spacing $standard-spacing;
      text-decoration: none;
      color: $black;
      border-right: solid $border-width--thicker $white;
      background-color: $light-gray;

      &:hover,
      &:focus,
      &:active,
      &.is-active {
        color: $white;
        background-color: $gray;
      }
    }
  }
}

/* Drupal 7 Version */
.tabs-primary {
  display: flex;
  margin-bottom: $standard-spacing;
  padding-left: 0;
  list-style: none;
  border-bottom: solid $border-width--thicker $gray;
  font-size: $p-font-size--smaller;

  &:nth-of-type(2) {
    margin-top: $half-spacing;
    margin-left: $standard-spacing;
    border-bottom-color: $light-gray;
  }

  a {
    display: block;
    margin: 0;
    padding: $half-spacing $standard-spacing;
    text-decoration: none;
    color: $black;
    border-right: solid $border-width--thicker $white;
    background-color: $light-gray;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      color: $white;
      background-color: $gray;
    }
  }
}
