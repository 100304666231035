/**
* @file
* 02-molecules/content/search/mabel-search/_mabel-search.scss
*/
$simple-search-button--width: 120px;
$simple-search-button--height: 64px;
$mabel-label-min-font-size: 17px;
$mabel-label-max-font-size: 25px;
$mabel-label-font-size: calc(#{$mabel-label-min-font-size} + #{strip-unit($mabel-label-max-font-size - $mabel-label-min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));

#islandora-search-container {
  background: linear-gradient(to right, transparentize($darkest-blue, 0.1) 0%, transparentize($darkest-blue, 0.2) 100%);
  padding: $double-spacing;
  display: flex;
  flex-flow: column;

  .block-title {
    display: block;
    margin: 0;
    margin-bottom: $half-spacing;
    color: $text-color-light;
    border: none;
    text-align: left;
    font-family: $sans-serif;
    font-size: $mabel-label-font-size;
    font-weight: $font-weight--bold;
  }

  .form-wrapper {
    position: relative;
  }

  .advanced-search-link {
    align-self: end;
    color: $white;
    margin-top: $standard-spacing;

    &:hover,
    &:focus,
    &:active {
      background-color: $darkest-blue;
      color: $lightest-green;
    }
  }
}

#islandora-solr-simple-search-form {
  .form-item-islandora-simple-search-query {
    + .form-submit {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: $simple-search-button--width;
      height: $simple-search-button--height;
      margin: 0;
      padding: $standard-spacing;
      border-radius: 0;
      background: $blue;
      color: $white;
      box-shadow: none;
      border: none;

      &:active,
      &:hover,
      &:focus {
        background: $dark-blue;
        transform: none;
      }
    }

    .form-text {
      width: calc(100% - #{$simple-search-button--width});
      height: $simple-search-button--height;
      margin: 0;
      border-color: $blue;
      font-size: $p-font-size;
    }
  }
}

input[title="Add field"],
input[title="Remove field"] {
  width: $toggle-size;
  height: $toggle-size;
  padding: 0;
  border-radius: 0;
  background: $blue;
  border: none;
  box-shadow: none;

  &:active {
    transform: none;
  }
}

.islandora-solr-advanced-controls {
  position: relative;
  margin-top: $standard-spacing;

  .form-item,
  .form-submit {
    display: inline-block;
    margin-right: $standard-spacing;
  }
}

/* Search box is positioned more prominently on front page */
.islandora-front-page {
  #islandora-search-container {
    width: 100%;
  }
}
