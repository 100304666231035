/**
* @file
* sites/mabel/pages/_object.scss
*/
a#clip {
  @include breakpoint($medium) {
    font-size: $p-font-size--smaller;
  }
  margin-bottom: $standard-spacing;
  margin-left: 0;
  font-size: $p-font-size--smaller;
}

.islandora-object-thumb,
.islandora-object-caption {
  a {
    display: inline-block;
  }
}

/* Gray box around objects */
.islandora-audio-content,
.islandora-basic-image-content,
.islandora-large-image-content,
.islandora-oralhistories-content,
.islandora-pdf-content,
.islandora-video-content {
  border: none;
  border-radius: 0;
  background: $light-gray;
}

body.page-islandora-object {
  .pane-page-title {
    h1 {
      display: inline-block;
      margin-top: 0;
      margin-right: $standard-spacing;
      font-size: $h4-font-size;
    }
  }

  /* grid view */
  .islandora-objects-grid-item {
    font-size: $p-font-size--smaller;

    break-word: break-all;
  }

  /* image viewer on single object page */
  .islandora-large-image-content {
    float: none;
    width: 100%;
  }

  /* Sidebar panels */
  .layout__region--first {
    .panel-pane {
      margin-bottom: $standard-spacing;
      padding: $standard-spacing;
      background: $lightest-gray;
      font-size: $p-font-size--smaller;
    }

    a {
      border-bottom-color: transparent;

      &:hover,
      &:focus,
      &:active {
        color: $dark-green;
        border-bottom-color: $dark-green;
      }
    }


    h2 {
      margin-top: 0;
      font-family: $sans-serif;
      font-size: $p-font-size;
    }
  }

  .layout__region--second {
    position: relative;
    padding-top: $double-spacing;
  }

  .pane-pm-existing-pages {
    margin-top: -$double-spacing;
  }
}

/* Details tab */
.islandora-metadata {
  .fieldset-title {
    font-family: $serif;
    font-size: $h2-font-size;
    text-decoration: none;
    color: $dark-blue;

    &:after {
      content: ' -';
    }
  }

  &.collapsed {
    .fieldset-title {
      &:after {
        content: ' +';
      }
    }
  }
}

html.js .islandora-metadata,
html.js .islandora-metadata.collapsed {
  border: solid $dark-blue $border-width;
  padding: $standard-spacing;
  margin-bottom: $standard-spacing;
}

html.js .islandora-metadata.collapsible .fieldset-legend {
  background-image: none;
}
