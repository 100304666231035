/**
 * @file
 * helpers/_align.scss
 * Helper classes to adjust alignments
 */


/* For align classes, added by drupal to float block elements, add spacing from text */
.align-right {
  margin-left: $standard-spacing;
}
.align-left {
  margin-right: $standard-spacing;
}
.align-center {
  margin-left: auto;
  margin-right: auto;
}

/* Adjust text-align properties */
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
