/**
* @file
* 02-molecules/content/search/quicksearch-form/_search.scss
*/
/* style listnav letters */
#quick-search-list-nav {
  .ln-letters {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    margin: 0 auto;

    @include breakpoint($medium) {
      margin: auto;
      justify-content: space-between;
    }

    a {
      color: $white;
      margin-right: $standard-spacing;
      margin-bottom: $half-spacing;

      &:hover,
      &:focus,
      &:active {
        color: $lightest-green;
        background: mix(black, $blue, 20%);
      }

      &.ln-disabled {
        color: mix(black, $blue, 30%);
        border-bottom-color: transparent;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
          outline: none;
        }
      }

        @include breakpoint($medium) {
          margin-right: 0;
          margin-bottom: 0;
        }
      }

    }
  }

/* Form block styles */
.quicksearch-form-container {
  padding: $double-spacing;
  background: $blue;

  h2 {
    margin-top: 0;
    border-bottom-color: $lightest-green;
  }

  #quick-search-form {
    margin: $double-spacing 0;
  }

  #quick-search-input {
    width: calc(100% - #{$standard-spacing});
  }
}
