/**
 * @file
 * 01-atoms/01-text/_blockquotes.scss
 */

blockquote {
  @include light-gray-bg;
  margin: $standard-spacing 0;
  padding: $double-spacing;
}

.pullquote,
.paragraph--type--majors-pull-quote {
  margin: 0 $standard-spacing;
  padding: $standard-spacing;
  font-style: italic;
  border-left: solid 3px $green;

  &.style-thin-border {
    border: 0;

    &:before {
      border-top: solid 3px $blue;
    }

    &:after {
      border-top: solid 3px $blue;
    }
  }
}
