/**
 * @file
 * templates/layouts/twocol_25_75/_layout--twocol25-75.scss
 */

.layout--twocol-25-75 {
  display: flex;
  flex-wrap: wrap;
}

.layout--twocol-25-75 > .layout__region {
  flex: 0 1 100%;
}

.layout--twocol-25-75 > .layout__region--first {
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 25%;
    max-width: 25%;
    padding-right: $standard-spacing;
  }
}

.layout--twocol-25-75 > .layout__region--second {
  @include breakpoint($medium) {
    flex: 0 1 75%;
    max-width: 75%;
    padding-left: $standard-spacing;
  }
}
