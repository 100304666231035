/**
* @file
* 00-molecules/navigation/horizontal-menu/_horizontal-menu.scss
*/

.menu  {
  &.horizontal {
    display: flex;
    justify-content: center;

    li {
      border-left: none;
    }
  }
}
