/**
* @file
* sites/mabel/pages/_search-results.scss
*/

.page-islandora-search {
  .pane-page-title {
    h1 {
      display: inline-block;
      margin-top: 0;
      margin-right: $standard-spacing;
    }
  }

  .page-content {
    margin-bottom: 0;
  }

  .islandora-solr-search-result {
    padding: 0 $standard-spacing $double-spacing;
    border-bottom: solid 1px $gray;

    &:last-of-type {
      border-bottom: none;
    }

    a {
      margin: initial;
      padding: 0;
    }

    dl.solr-fields {
      border: none;
    }
    .solr-fields dt,
    .solr-fields dd {
      border-color: $light-gray;
    }

    dl {
      &.solr-thumb,
      &.solr-fields {
        @include breakpoint(max-width $medium) {
          float: none;
          width: 100%;
        }

        &.solr-thumb {
          text-align: center;
        }
      }
    }

    .islandora-inline-metadata {
      dt {
        font-weight: $font-weight--regular;
      }
    }
  }

  .islandora-solr-grid {
    a {
      margin: 0;
      padding: 0;
      border: none;
      font-size: $p-font-size--smaller;
    }
  }

  .layout__region--second {
    position: relative;
    padding-top: $double-spacing;
  }
}

/* The following are for the resul limit/sort/display options, which also affect object 'result' pages, ie 'browse the collection' */
.expand-solr-panel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: $half-spacing $standard-spacing;
  text-align: left;
  color: $black;
  border-radius: 0;
  background-color: $light-gray;

  .material-icons {
    position: absolute;
    top: $quarter-spacing;
    top: 50%;
    right: 0;
    transform: translate(-4px, -50%);
    font-size: 18px;
  }
}

.date-filter-toggle-text {
  display: none;
}

.date-range-filter-wrapper {
  display: block !important;
}

#skip-to-search-results:focus {
  position: relative !important;
  margin-right: $double-spacing;
}

#islandora-solr-result-count {
  display: inline-block;
  margin-right: $double-spacing;
}

#secondary-display-profiles {
  display: none;
}

#edit-range-slider-submit {
  border-radius: 0;
}

#edit-date-filter-date-filter-submit {
  margin-left: 100%;
  transform: translatex(-100%);
  border-radius: 0;
}
#edit-date-filter-date-filter-from,
#edit-date-filter-date-filter-to {
  padding: $half-spacing;
}
