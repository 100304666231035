/**
 * @file
 * 00-utilities/color/_brand.scss
 */

// Shades of gray
$black: #262b2f;
$gray: #667986;
$light-gray: #e3e7e9;
$lightest-gray: #f5f6f7;
$white: #fff;

// WWU brand colors
$dark-blue: #003f87;
$blue: #007ac8;
$dark-green: #006b3f;
$light-green: #bad80a;

// Almost brand colors
$darkest-blue: #002f5e;
$light-blue: #41B6FF;
$green: #4A821E;
$lightest-green: #d6e86c;

// Special Use Colors
$red: #cc2d30;
$yellow: #ffc61e;
$link-blue: #006ebb;
