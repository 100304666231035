/**
* @file
* 00-molecules/navigation/wwu-menu/_wwu-menu.scss
*/

.quick-links {
  @include breakpoint($medium) {
    font-family: $condensed;
  }

  &.desktop {
    .menu {
      display: flex;
      flex-flow: row nowrap;
      margin: 0;
      border: none;
      background-color: transparent;

      li {
        flex: 1;
        margin: 0;
        border: none;

        a {
          @include breakpoint($large) {
            height: $toggle-size--larger;
            line-height: $toggle-size--larger;
          }
          width: 100%;
          height: $toggle-size;
          padding: 0 ($standard-spacing * 1.5);
          text-align: center;
          line-height: $toggle-size;
          outline: ($half-spacing / 2) solid transparent;

          &:hover,
          &:focus,
          &:active {
            color: $black;
            outline: none;
            background-color: $light-green;
          }

          &:focus {
            outline: ($half-spacing / 2) dotted $black;
            outline-offset: 0;
          }
        }

        &:nth-of-type(1) {
          a {
            @include breakpoint($medium) {
              color: $white;
              background-color: $dark-blue;
            }

            &:hover,
            &:focus,
            &:active {
              color: $black;
              background-color: $light-green;
            }
          }
        }
      }
    }
  }
}
