/**
* @file
* organisms/email-signature/email-signature.scss
*/
.email_signature_table {
  width: initial;

  tr {
    background: transparent;
  }

  td {
    border: none;
  }

  p {
    font-size: inherit;
  }
}

#copy_button {
  &:focus {
    background-color: $lightest-gray;
    color: $black;
    border-color: $black;
  }
}
