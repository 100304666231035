/**
* @file
* organisms/blocks/block/_block-template.scss
*/
.block--full-page,
.block--half-page {
  position: relative;
  z-index: 0;

  .block__title {
    margin: 0;
    padding: 0;
  }
}

.block--full-page {
  @include breakpoint($small) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  @include breakpoint($large) {
    padding-right: $double-spacing * 4;
    padding-left: $double-spacing * 4;
  }
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0 -$standard-spacing;
}

.block--half-page {
  @include breakpoint($small) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  @include breakpoint($large) {
    padding-right: $double-spacing * 4;
    padding-left: $double-spacing * 4;
  }
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100vw;
  min-height: 45vh;
  margin: 0 -$standard-spacing;
}

.layout--twocol-section--50-50 {
  .block--half-page {
    @include breakpoint($small) {
      width: 50vw;
    }
  }

  .layout__region--first {
    .block--half-page {
      @include breakpoint($medium) {
        margin-left: -$standard-spacing;
      }
      @include breakpoint($large) {
        margin-left: calc((100vw - #{$max-content-width}) / -2);
      }
    }
  }

  .layout__region--second {
    .block--half-page {
      @include breakpoint($medium) {
        margin-right: -$standard-spacing;
      }
      @include breakpoint($large) {
        margin-right: calc((100vw - #{$max-content-width}) / -2);
      }
    }
  }
}

/* Hide linked title for embedded blocks */
.node--view-mode-block {
  & > h2 {
    display: none;
  }
}
