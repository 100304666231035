/**
* @file
* organisms/full-spreads/_full-spread.scss
* for Make Waves custom block type
*/
$play-icon-size: 200px;

.make-waves-full-spread {
  @include breakpoint($max-one-col-width) {
    transform: translatex(calc((100vw - #{$max-one-col-width}) / -2));
  }
  width: 100vw;
  transform: translatex(-$standard-spacing);

  .container {
    @include breakpoint($medium) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    }
    display: grid;

    grid-template-areas:
    "image"
    "content";
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;

    &.copy-left {
      @include breakpoint($medium) {
        grid-template-areas: "content image";
      }
    }

    &.copy-right {
      @include breakpoint($medium) {
        grid-template-areas: "image content";
      }
    }
  }

  .image {
    grid-area: image;

    img {
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .content {
    display: grid;
    align-content: center;

    grid-area: content;
    justify-items: center;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $play-icon-size;
    transition: $medium-transition-speed $standard-ease opacity;
    transform: translate(-50%, -50%);
    opacity: 0.8;
  }

  .play-link {
    position: relative;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    color: $white;
    border: none;

    &:hover,
    &:focus,
    &:active {
      .play-icon {
        opacity: 1;
      }
    }
  }
}
