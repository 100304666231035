/*
 * @file
 * templates/layouts/threecol_33_34_33/_layout--threecol-33-34-33.scss
 */

.layout--threecol-33-34-33 {
  display: flex;
  flex-wrap: wrap;
}

.layout--threecol-33-34-33 > .layout__region {
  flex: 0 1 100%;
}

.layout--threecol-33-34-33 > .layout__region--first{
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 33%;
    max-width: 33%;
    padding-right: $standard-spacing;
  }
}

.layout--threecol-33-34-33 > .layout__region--second {
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 34%;
    max-width: 34%;
    padding: 0 $standard-spacing;
  }
}

.layout--threecol-33-34-33 > .layout__region--third {
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 33%;
    max-width: 33%;
    padding-left: $standard-spacing;
  }
}
