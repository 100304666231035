/**
* @file
* organisms/page-404/_page-404.scss
*/

.wrapper--404 {
  @include breakpoint($medium) {
    margin-top: -$toggle-size * 2;
  }
  @include breakpoint($large) {
    margin-top: -$toggle-size--larger * 2;
  }
  background: $dark-blue;

  h1 {
    padding: 0;
    color: $lightest-green;
    font-size: $h1-font-size--larger;
  }

  .text {
    padding: $triple-spacing;
    text-align: center;

    .tagline {
      color: $white;
    }
  }
}

.svg-box {
  margin-bottom: -5vw;
}

.site-header {
  .nav--404 {
    .nav--main {
      @include breakpoint($medium) {
        margin: 0;
        transform: translatey(32vw);
      }
    }
  }
}

/* Animations */
/* Boat times */
#tossingboat {
  transform-origin: 50% 50%;
  -webkit-animation-name: tossingboat;
  animation-name: tossingboat;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes tossingboat {
  0% {
    transform: rotate(-4deg);
  }
  40% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}

@-webkit-keyframes .tossingboat {
  0% {
    -webkit-transform: rotate(-4deg);
  }
  50% {
    -webkit-transform: rotate(4deg);
  }
  100% {
    -webkit-transform: rotate(-4deg);
  }
}

/* Boat waves */
#rockingwaves {
  transform-origin: 50% 50%;
  -webkit-animation-name: rockingwaves;
  animation-name: rockingwaves;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-timing-function: ease-in-out;
}

@keyframes rockingwaves {
  0% {
    transform: rotate(-2.5deg);
  }
  40% {
    transform: rotate(2.5deg);
  }
  100% {
    transform: rotate(-2.5deg);
  }
}

@-webkit-keyframes rockingwaves {
  0% {
    -webkit-transform: rotate(-2.5deg);
  }
  50% {
    -webkit-transform: rotate(2.5deg);
  }
  100% {
    -webkit-transform: rotate(-2.5deg);
  }
}

/* Fog times */
#fog {
  -webkit-animation-name: fog;
  -moz-animation-name: fog;
  animation-name: fog;
  -webkit-animation-duration: 60s;
  -moz-animation-duration: 60s;
  animation-duration: 60s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  animation-direction: alternate;
}

@keyframes fog {
  0% {
    transform: scale3d(0.3, 0.3, 0.3) rotate(4deg);
    opacity: 0;
  }

  30%,
  50% {
    transform: scale(1.3) rotate(-4deg) translate3d(-50px, -15px, -15px);
    opacity: 0.4;
  }

  40%,
  70% {
    transform: scale(1.1) rotate(4deg) translate3d(50px, 15px, 15px);
    opacity: 0.3;
  }

  100% {
    transform: scale3d(2, 2, 2);
    opacity: 0;
  }
}

@-webkit-keyframes fog {
  0% {
    -webkit-transform: scale3d(0.3, 0.3, 0.3) rotate(4deg);
    opacity: 0;
  }

  30%,
  50% {
    -webkit-transform: scale(1.3) rotate(-4deg) translate3d(-50px, -15px, -15px);
    opacity: 0.4;
  }

  40%,
  70% {
    -webkit-transform: scale(1.1) rotate(4deg) translate3d(50px, 15px, 15px);
    opacity: 0.3;
  }

  100% {
    -webkit-transform: scale3d(2, 2, 2);
    opacity: 0;
  }
}

@-moz-keyframes fog {
  0% {
    -moz-transform: scale3d(0.3, 0.3, 0.3) rotate(4deg);
    opacity: 0;
  }

  30%,
  50% {
    -moz-transform: scale(1.3) rotate(-4deg) translate3d(-50px, -15px, -15px);
    opacity: 0.4;
  }

  40%,
  70% {
    -moz-transform: scale(1.1) rotate(4deg) translate3d(50px, 15px, 15px);
    opacity: 0.3;
  }

  100% {
    -moz-transform: scale3d(2, 2, 2);
    opacity: 0;
  }
}

/* Big Waves */
#over_waves {
  transform-origin: 50% 50%;
  -webkit-animation-name: wave;
  animation-name: wave;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes wave {
  0% {
    transform: rotate(1.1deg);
  }
  50% {
    transform: rotate(-1.1deg);
  }
  100% {
    transform: rotate(1.1deg);
  }
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(1.1deg);
  }
  50% {
    -webkit-transform: rotate(-1.1deg);
  }
  100% {
    -webkit-transform: rotate(1.1deg);
  }
}

/* Back Rock Waves */
#backrock_waves {
  transform-origin: 50% 50%;
  -webkit-animation-name: backnforth;
  animation-name: backnforth;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes backnforth {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-30px, -1px, -1px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(30px, 1px, 1px);
  }
}

@-webkit-keyframes backnforth {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-30px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(30px, 0, 0);
  }
}

/* Colors */
.vik-st0 {
  opacity: 1;

  fill: #72b8db;
}
.vik-st1 {
  fill: #f7f7f7;
}
.vik-st2 {
  fill: #e0e0e0;
}
.vik-st3 {
  fill: #ccc;
}
.vik-st4 {
  fill: #bfbfbf;
}
.vik-st5 {
  fill: #808080;
}
.vik-st6 {
  fill: $dark-blue;
}
.vik-st7 {
  fill: #444;
}
.vik-st8 {
  fill: none;
  stroke: #211e1c;
  stroke-miterlimit: 10;
  stroke-width: 2;
}
.vik-st9 {
  opacity: 0.85;
}
.vik-st10 {
  fill: $blue;
}
.vik-st11 {
  fill: $white;
}
.vik-st12 {
  stroke: #211e1c;
  stroke-miterlimit: 10;
  stroke-width: 5;
}
.vik-st13 {
  opacity: 0.87;

  fill: $dark-blue;
}
.vik-st14 {
  opacity: 0.85;

  fill: $dark-blue;
}
.vik-st15 {
  fill: #353535;
}
.vik-st16 {
  opacity: 0.87;
}
.vik-st17 {
  fill: none;
}
.vik-st18 {
  font-family: "Muli";
}
.vik-st19 {
  font-size: 40px;
}
.vik-st20 {
  letter-spacing: 1;
}
.vik-st22 {
  font-size: 36px;
  font-weight: 700;
}
.vik-st24 {
  font-size: 99.8396px;
}
.vik-st25 {
  font-size: 49px;
}
