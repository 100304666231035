/**
* @file
* molecules/components/card/_card.scss
*/

.card,
.block--card {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-content: start;
  margin-bottom: $standard-spacing;
  transition: $fast-transition-speed $standard-ease;
  padding-bottom: $standard-spacing;

  &:hover,
  &:focus-within {
    z-index: 1;
    transform: translate(-$border-width, -$border-width);
    box-shadow: $focus-shadow;
  }

  .image {
    overflow: hidden;
    flex: 0 1 100%;
    order: 1;
    width: 100%;
    margin-bottom: $half-spacing;

    img {
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .taxonomy-terms {
    flex: 0 1 100%;
    order: 2;
    padding: 0 $half-spacing;
  }

  .title {
    flex: 0 1 100%;
    order: 3;
    padding: 0 $half-spacing;

    .block__title {
      margin-top: 0;
    }

    a {
      display: inline-block;
      text-decoration: none;
      font-family: $sans-serif;
      font-size: $p-font-size;
      font-weight: $font-weight--bold;
      line-height: initial;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
        background: none;
      }
    }
  }

  .body {
    flex: 0 1 100%;
    order: 4;
    padding: 0 $half-spacing;

    .date {
      font-weight: $font-weight--semibold;
    }
  }
}

/* Make sure configure button works in layout builder */
.layout-builder-block.card .title a::after,
.layout-builder-block.block--card .title a::after {
  position: initial;
}
