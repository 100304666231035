/**
 * @file
 * 01-atoms/lists/_lists.scss
 */

ul,
ol {
  margin: 0 0 $standard-spacing;
}

li {
  line-height: 1.5em;
}

.spaced {
  li {
    @include breakpoint($medium) {
      margin-bottom: $standard-spacing;
    }
    margin-bottom: $half-spacing;
  }
}

dl {
  margin-bottom: $half-spacing;
  dt {
    margin-top: $half-spacing;
    font-weight: 600;
  }
  dd {
    margin: 0;
  }
}
