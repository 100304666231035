/*
 * @file
 * templates/layouts/_layouts.scss
 */

main {
  .layout__region {
    max-width: calc(100vw - (#{$standard-spacing} * 2));
  }
}
