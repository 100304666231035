/**
 * @file
 * organisms/quicksearch/_quicksearch.scss
 */
/* listnav.js styles, which are needed for functionality */
// Any result with both classes should be shown
.listNavShow {
  &.quickSearchShow {
    display: list-item;
  }
}

// Any result with either class should be hidden
.listNavHide,
.quickSearchHide {
  display: none;
}

// This is the no results message generated by the listnav library, which does not fit our needs--hide for now
.listNavShow.ln-no-match {
  display: none !important;
}

.results-text {
  margin: $standard-spacing 0 0 $double-spacing;
  font-weight: bold;

  & + .results-text-heading {
    margin: $half-spacing 0 $standard-spacing $double-spacing;
    font-weight: bold;
  }
}
/* END listnav.js styles */
