/**
* @file
* 02-molecules/navigation/menu-flyout/_menu-flyout.scss
*/
.ultimenu__region {
  @include blue-bg;
  @include breakpoint($medium) {
    flex-flow: row nowrap;
    justify-content: space-around;
    width: 100%;
    height: inherit;
    padding-top: (($toggle-size--larger / 2) + $standard-spacing);
    padding-bottom: $double-spacing;
  }
  @include breakpoint($large) {
    padding: $triple-spacing * 2;
  }
  display: flex;
  flex-flow: column;
  padding: $standard-spacing;

  .menu .menu li {
    border-left: none;
  }

  h2, h3 {
    margin-top: 0;
    color: $lightest-green;
    font-family: $sans-serif;
    font-size: $h5-font-size;
    font-weight: $font-weight--semibold;
    text-shadow: 1px 1px 1px transparentize($dark-blue, 0.1);

    a:not(.button) {
      color: $lightest-green;
    }
  }

  h3 {
    font-size: $h5-font-size--smaller;
  }


  > div {
    @include breakpoint($medium) {
      flex: 0 1 40%;
    }
    flex: 0 1 100%;
    margin: 0 $standard-spacing;
  }
}
