/**
* @file
* templates/event-page-single/_event-page-single.scss
*/
.page-node-type-event {
  .layout__region--second {
    text-align: center;
  }
}

.block--nodeeventfield-event-link {
  margin-top: $triple-spacing;

  a {
    @extend .button;
  }
}
