/**
 * @file
 * 01-atoms/01-text/_horizontal-rule.scss
 */

hr {
  @include breakpoint($medium) {
    border-width: $border-width--thicker;
  }
  margin: $standard-spacing 0;
  border: 0;
  border-bottom: $border-width solid $light-gray;
}
