/**
* @file
* sites/mabel/panes/_sort.scss
*/
.pane-islandora-solr-sort {
  display: flex;
  align-items: center;
  margin-top: -$double-spacing;

  .pane-title {
    margin-right: $standard-spacing;
  }

  .item-list {
    flex: 1;

    ul {
      display: grid;
      margin: 0;
      list-style: none;

      grid-column-gap: $half-spacing;
      grid-template-columns: repeat(4, 1fr);
    }

    li {
      flex: 1;
      margin: 0;
      text-align: center;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $quarter-spacing;
        text-decoration: none;
        color: $white;
        background: $blue;

        &:hover,
        &:focus,
        &:active {
          outline-color: $dark-blue;
          background: $dark-blue;
        }

        &.active {
          color: $dark-blue;
          background: $light-green;

          &:hover,
          &:focus,
          &:active {
            color: $light-green;
            background: $dark-blue;
          }
        }
      }
    }

    .sort-asc {
      margin-left: $standard-spacing;
      transform: rotate(-90deg);
      &.material-icons {
        font-size: $double-spacing;
      }
    }

    .sort-desc {
      margin-left: $standard-spacing;
      transform: rotate(90deg);
      &.material-icons {
        font-size: $double-spacing;
      }
    }
  }
}
