/**
 * @file
 * 01-atoms/_page.scss
 */

html {
  * {
    box-sizing: border-box;
  }

  scroll-behavior: smooth;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px,1px,1px,1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
