/**
* @file
* 00-molecules/navigation/wwu-mobile-menu/_wwu-mobile-menu.scss
*/
$close-toggle-size: 64px;

.quick-links {
  &.mobile {
    @include breakpoint($medium) {
      display: none;
    }
    background-color: $dark-blue;

    .toggle-close-menu {
      position: absolute;
      right: 0;
      width: $close-toggle-size;
      height: $close-toggle-size;
    }

    .menu {
      display: grid;
      align-items: end;
      margin: 0;
      border-bottom: solid $border-width--thinner $white;

      grid-template-areas: "apply gift language ."
      "calendar directory map mywestern";
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: $close-toggle-size auto;
      justify-items: center;

      li {
        width: 100%;
        border-top: solid $border-width--thinner $white;

        a {
          width: 100%;
          padding: $half-spacing;
          color: $white;

          &:hover,
          &:focus,
          &:active {
            color: $black;
            outline: none;
            background-color: $light-green;
          }
        }

        &.apply, &.gift {
          align-self: center;
          border-top: none;

          .button {
            padding: ($standard-spacing * 0.75);
            border: solid $border-width $white;
            font-size: $p-font-size;

            &:hover,
            &:focus,
            &:active {
              border-color: transparent;
            }
          }
        }

        &.apply {
          margin: 0 $half-spacing;
          grid-area: apply;
        }

        &.gift {
          margin-left: $standard-spacing;
          grid-area: gift;
        }

        &.language {
          align-self: center;
          padding-left: $triple-spacing;
          border: none;

          grid-area: language;

          button {
            z-index: inherit;
            padding: $half-spacing $standard-spacing;
            color: $white;
            border: $border-width solid $white;
            border-radius: $triple-spacing;
            background-color: $dark-blue;
            box-shadow: $border-width $border-width $white;

            &:hover {
              color: $black;
              border-color: transparent;
              background-color: $light-green;
            }
          }
        }

        &.icon-with-text {
          width: inherit;
          height: inherit;

          .icon-text {
            margin-top: 0;
          }
        }

        &.calendar {
          grid-area: calendar;
        }

        &.directory {
          grid-area: directory;
        }

        &.map {
          grid-area: map;
        }

        &.mywestern {
          grid-area: mywestern;
        }
      }
    }
  }
  .language-options {
    position: absolute;
    width: 100%;
    height: fit-content;
    padding: $triple-spacing;
    background-color: $dark-blue;

    .select-language {
      align-self: center;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: $double-spacing;
      padding: $standard-spacing;
      list-style-type: none;
      border: none;

      li {
        margin-bottom: $standard-spacing;
        border: none;
      }
    }
  }
}
