/*
* @file
* templates/layouts/threecol/_threecol.scss
*/

.layout--threecol-section {
  display: flex;
  flex-wrap: wrap;

  > .layout__region {
    flex: 0 1 100%;
  }

  > .layout__region--first {
    @include breakpoint($small) {
      padding-right: $half-spacing;
    }
    @include breakpoint($medium) {
      padding-right: $standard-spacing;
    }
  }

  > .layout__region--second {
    @include breakpoint($small) {
      padding-right: $half-spacing;
      padding-left: $half-spacing;
    }
    @include breakpoint($medium) {
      padding-right: $standard-spacing;
      padding-left: $standard-spacing;
    }
  }

  > .layout__region--third {
    @include breakpoint($small) {
      padding-left: $half-spacing;
    }
    @include breakpoint($medium) {
      padding-left: $standard-spacing;
    }
  }

  &.layout--threecol-section--25-50-25 > .layout__region--first,
  &.layout--threecol-section--25-50-25 > .layout__region--third,
  &.layout--threecol-section--25-25-50 > .layout__region--first,
  &.layout--threecol-section--25-25-50 > .layout__region--second,
  &.layout--threecol-section--50-25-25 > .layout__region--second,
  &.layout--threecol-section--50-25-25 > .layout__region--third {
    @include font-size--smaller;
    @include breakpoint($small) {
      flex: 0 1 25%;
    }
  }
  &.layout--threecol-section--25-50-25 > .layout__region--second,
  &.layout--threecol-section--25-25-50 > .layout__region--third,
  &.layout--threecol-section--50-25-25 > .layout__region--first {
    @include breakpoint($small) {
      flex: 0 1 50%;
    }
  }
  &.layout--threecol-section--33-34-33 > .layout__region--first,
  &.layout--threecol-section--33-34-33 > .layout__region--third {
    @include font-size--smaller;
    @include breakpoint($small) {
      flex: 0 1 33%;
    }
  }
  &.layout--threecol-section--33-34-33 > .layout__region--second {
    @include font-size--smaller;
    @include breakpoint($small) {
      flex: 0 1 34%;
    }
  }
}

.layout--threecol--page-width {
  @include breakpoint($large) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  margin-left: -$standard-spacing;
  margin-right: -$standard-spacing;

  > .layout__region {
    flex: 0 1 100%;
    max-width: none;
  }

  > .layout__region--first,
  > .layout__region--third {
    @include breakpoint($small) {
      flex: 0 1 33%;
    }
  }

  > .layout__region--second {
    @include breakpoint($small) {
      flex: 0 1 34%;
    }
  }
}
