/**
* @file
* 01-atoms/lists/_menu-items.scss
*/

.menu {
  li {
    @include breakpoint($medium) {
      border-left-width: $border-width--thicker;
    }
    border-left: solid $border-width $light-gray;
    margin-bottom: 0;

    a {
      display: inline-block;
      width: 100%;
      margin: inherit;
      margin-bottom: 0;
      padding: $half-spacing ($half-spacing * 4) $half-spacing ($half-spacing * 2);
      transition: $medium-transition-speed $standard-ease background-color;
      color: $black;
      text-decoration: none;
    }
  }
}

nav,
.menu--erm-menu {
  .menu {
    li {
      border-left: none;
    }
  }
}
