/**
* @file
* sites/mabel/panes/_search-navigation.scss
*/

.pane-islandora-solr-search-navigation {
  @include breakpoint($small) {
    display: grid;

    grid-template-columns: 3fr 1fr 1fr;
    grid-template-rows: auto;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: $standard-spacing;

  div {
    @include breakpoint($medium) {
      font-size: $p-font-size--smaller;
    }
    display: inline-block;
    font-size: $p-font-size--smaller;
  }

  .button {
    @include breakpoint($medium) {
      padding: $standard-spacing;
    }
    margin-bottom: 0;
    padding: $half-spacing;
  }

  #islandora-solr-search-prev-link,
  #islandora-solr-search-next-link {
    justify-self: end;
  }

  #islandora-solr-search-return-link,
  #islandora-solr-search-prev-link {
    a {
      &:before {
        content: "\2039  ";
        font-size: 1em;
      }
    }
  }

  #islandora-solr-search-next-link {
    a {
      &:after {
        content: " \203A";
        font-size: 1em;
      }
    }
  }
}
