/**
* @file
* 01-atoms/images/image-stylized/_image-stylized.scss
*/

.image--stylized {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  * {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
