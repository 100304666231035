/**
* @file
* 01-atoms/text/_all.scss
*/

html {
  @include breakpoint($large) {
    font-size: $p-font-size--max;
  }
  color: $text-color;
  font-family: $sans-serif;
  font-size: $p-font-size;
}
