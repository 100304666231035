/**
* @file
* organisms/views/views-bulk-operations/_views-bulk-operations.scss
* These styles fix the checkboxes on the views generated by VBO.
*/

.vbo-view-form,
.views-field-views-bulk-operations-bulk-form {
  input[type="checkbox"] {
    position: relative;
    top: $half-spacing / 2;
    opacity: 1;
  }

  .checkbox {
    display: inline-block;
    margin: 0;
  }

  .views-row {
    margin: $half-spacing 0;
  }

  .form-actions {
    text-align: right;
  }
}
