/**
 * @file
 * 01-atoms/images/_images.scss
 */

img,
picture {
  max-width: 100%;
  height: auto;
  margin: 0;
}
picture source,
picture img {
  width: 100%;
  height: auto;
}
