/**
 * @file
 * organisms/sections/teaser-list/_teaser-list.scss
 */

.teaser-list__row {
  & + & {
    margin-top: $standard-spacing;
  }
}
