/**
* @file
* organisms/blocks/_blocks--make-waves.scss
*/
.block--mave-waves {
  h2 {
    text-align: center;
    text-transform: uppercase;
    border-bottom: none;
    line-height: 0.8em;
  }

  h3, h4, h5, h6 {
    text-transform: uppercase;
  }

  &.white-bg {
    h2, h3, h4, h5, h6 {
      color: $blue;
    }
  }
}

.block--make-waves-basic-block {
  @include breakpoint($medium) {
    padding: $double-spacing;
  }
  @include breakpoint($large) {
    padding: $triple-spacing;
  }
  padding: $standard-spacing;
}
