/**
 * @file
 * organisms/page/_alerts.scss
 */
$logo-size: 100px;

.alert {
  width: 100%;
  padding: $double-spacing;

  .alert-header {
    font-family: $sans-serif;
    border-bottom: none;
    margin-top: 0;
  }

  .alert-body {
    margin: $double-spacing 0;
  }

  .alert-icon {
    width: $logo-size;
    float: left;
    margin-right: $double-spacing;

    svg {
      position: relative;
      width: $logo-size;
      height: auto;
    }
  }

  .alert-footer {
    text-align: right;
  }

  &.emergency {
    color: $white;
    background-color: $red;

    .alert-header {
      color: $white;
    }

    svg {
      fill: $white;
    }
  }

  &.weather {
    color: $dark-blue;
    background-color: $yellow;

    .alert-header {
      color: $dark-blue;
    }

    svg {
      fill: $dark-blue;
    }
  }
}
