/**
 * @file
 * 01-atoms/_transitions.scss
 */
.slow {
  transition-duration: $slow-transition-speed;
}

.medium {
  transition-duration: $medium-transition-speed;
}

.fast {
  transition-duration: $fast-transition-speed;
}

.linear {
  transition-timing-function: linear;
}

.ease {
  transition-timing-function: $standard-ease;
}
