/**
* @file
* organisms/profile_profile.scss
*/
$profile-photo-size--smaller: 300px;
$profile-photo-size: 400px;

.user-profile {
  display: flex;
  flex-flow: row wrap;

  .profile-left {
    flex: 1 1 33%;
  }

  .profile-right {
    @include breakpoint($medium) {
      padding-left: $double-spacing;
      text-align: left;
    }
    flex: 1 1 66%;
    text-align: center;
  }

  .profile-photo {
    @include breakpoint($small) {
      width: $profile-photo-size;
      height: $profile-photo-size;
    }
    @include breakpoint($medium) {
      width: $profile-photo-size--smaller;
      height: $profile-photo-size--smaller;
    }
    @include breakpoint($large) {
      width: $profile-photo-size;
      height: $profile-photo-size;
    }
    width: $profile-photo-size--smaller;
    height: $profile-photo-size--smaller;
    margin: auto;
    margin-top: $triple-spacing;
  }
  .website {
    text-align: center;
  }

  .social-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: $double-spacing;
  }

  .social-icon {
    margin-right: $standard-spacing;
    margin-bottom: $standard-spacing;
  }

  .office,
  .contact {
    display: flex;
    flex-flow: row wrap;

    h2 {
      flex: 0 0 100%;
      margin-bottom: 0;
    }

    h3 {
      margin-top: $standard-spacing;
    }

    div {
      flex: 0 1 50%;
    }
  }
}
