/**
* @file
* templates/front-page/_front-page.scss
*/

.path-frontpage,
.front {
  .splash {
    img {
      object-position: 50%;
    }

    .right-aligned-bar {
      @include breakpoint($medium) {
        margin-top: inherit;
      }
      margin-top: 40vh;
    }
  }

  main {
    overflow-x: hidden;
    margin-bottom: 0;

    .block--page-title-block {
      position: absolute !important;
      overflow: hidden;
      clip: rect(1px,1px,1px,1px);
      width: 1px;
      height: 1px;
      word-wrap: normal;
    }
  }

  .layout__region--top,
  .layout__region--middle,
  .layout__region--bottom {
    @include breakpoint($medium) {
      padding-top: $standard-spacing * 2;
      padding-bottom: $standard-spacing * 2;
    }
    @include breakpoint($large) {
      padding-top: $triple-spacing;
      padding-bottom: $triple-spacing;
    }
    padding-top: $standard-spacing;
    padding-bottom: $double-spacing;
  }

  .layout--twocol-bricks {
    .layout__region {
      position: relative;
    }

    .layout__region--first-above {
      background-color: $lightest-gray;

      &:before,
      &:after {
        background-color: $lightest-gray;
      }
    }

    .layout__region--second-above {
      @include breakpoint($medium) {
        margin-top: $double-spacing;
      }
      background-color: $light-gray;

      &:before,
      &:after {
        background-color: $light-gray;
      }
    }

    .layout__region--first-below {
      background-color: $lightest-bg-blue;

      &:before,
      &:after {
        background-color: $lightest-bg-blue;
      }
    }
    .layout__region--second-below {
      @include breakpoint($medium) {
        margin-top: $double-spacing;
      }
      background-color: $light-bg-blue;

      &:before,
      &:after {
        background-color: $light-bg-blue;
      }
    }

    .layout__region--first-above,
    .layout__region--first-below,
    .layout__region--second-above,
    .layout__region--second-below {
      padding-bottom: $double-spacing;

      /* fake the full width background for color block bg sections */
      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        top: 0;
        display: block;
        width: $standard-spacing + 3px;
        height: 100%;
        content: "";
      }
    }

    .layout__region--first-above,
    .layout__region--first-below {
      @include breakpoint($large) {
        padding-right: $triple-spacing;
        padding-bottom: $double-spacing * 4;
      }
      h2 {
        @include breakpoint($large) {
          font-size: $h2-font-size--max--largest;
        }
        font-size: $h2-font-size--largest;
        font-family: $title-font;
        text-transform: uppercase;
        font-weight: $font-weight--black;
        text-align: left;
        color: $dark-blue;
      }

      .block--image {
        position: relative;

        img {
          @include breakpoint($medium) {
            width: calc(115% + (#{$standard-spacing} * 3));
            transform: translatex(-$standard-spacing);
          }
          @include breakpoint($large) {
            width: calc(125% + ((100vw - #{$max-content-width}) / 2));
            transform: translatex(calc((100vw - #{$max-content-width}) / -2));
          }
          display: block;
          width: 100vw;
          max-width: none;
          transform: translatex(-$standard-spacing);
        }
      }

      /* fake the full width background for color block bg sections */
      &:before {
        @include breakpoint($medium) {
          width: $standard-spacing + 3px;
        }
        @include breakpoint($large) {
          width: calc(((100vw - #{$max-content-width})/2) + 3px);
          transform: translatex(calc(-100% + 3px));
        }
        left: 0;
        transform: translatex(calc(-100% + 3px));
      }

      &:after {
        @include breakpoint($medium) {
          width: 0;
        }
        right: 0;
        transform: translatex(calc(100% - 3px));
      }
    }

    .layout__region--second-above,
    .layout__region--second-below {
      @include breakpoint($medium) {
        padding-left: $double-spacing;
      }
      @include breakpoint($large) {
        padding-top: $standard-spacing * 2;
        padding-bottom: $double-spacing * 4;
        padding-left: $triple-spacing;
      }
      h2 {
        @include breakpoint($large) {
          font-size: $h2-font-size--max--largest;
        }
        font-size: $h2-font-size--largest;
        font-family: $title-font;
        text-transform: uppercase;
        font-weight: $font-weight--black;
        text-align: left;
        color: $dark-blue;
      }

      /* fake the full width background for color block bg sections */
      &:before {
        @include breakpoint($medium) {
          width: 0;
        }
        left: 0;
        transform: translatex(calc(-100% + 3px));
      }
      &:after {
        @include breakpoint($medium) {
          width: $standard-spacing + 3px;
        }
        @include breakpoint($large) {
          width: calc(((100vw - #{$max-content-width})/2) + 3px);
          transform: translatex(calc(100% - 3px));
        }
        right: 0;
        transform: translatex(calc(100% - 3px));
      }
    }
  }

  .card-list {
    .card {
      > div {
        padding: 0;
      }
    }
  }
}

/* Bright Edge styles */
.be-ix-link-block {
  a {
    margin: 0;
    border-bottom: none;
  }

  h4 {
    border-bottom: none;
  }
}
