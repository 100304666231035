/**
* @file
* 01-atoms/overlays/_overlays.scss
*/
$overlay-transparency: 0.4;
.blue-overlay,
.dark-blue-overlay,
.black-overlay,
.gray-overlay {
  &:after {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.blue-overlay,
.dark-blue-overlay,
.black-overlay, {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }
}

.blue-overlay {
  background: $blue;

  &:after {
    background: transparentize($blue, $overlay-transparency);
  }
}

.dark-blue-overlay {
  background: $dark-blue;

  &:after {
    background: transparentize($dark-blue, $overlay-transparency);
  }
}

.black-overlay {
  background: $black;

  &:after {
    background: transparentize($black, $overlay-transparency);
  }
}

.gray-overlay {
  background: $light-gray;

  &:after {
    background: transparentize($light-gray, $overlay-transparency);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-blue;
  }

  p {
    color: $black;
  }
}
