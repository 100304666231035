/**
 * @file
 * molecules/navigation/pager/_pager.scss
 */

.pager ul {
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 0 9px;

    &.is-active {
      a {
        text-decoration: none;
        color: $black;
      }
    }
  }
}
