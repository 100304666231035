/**
* @file
* 01-atoms/global/_off-canvas.scss
*/
#drupal-off-canvas {
  font-size: 0.5em;
}

#drupal-off-canvas ul {
  padding: $half-spacing 0;
}

#drupal-off-canvas a {
  line-height: 1.5em;
}

#drupal-off-canvas input[type="checkbox"] {
  position: relative;
  display: inline;
  width: initial;
  height: initial;
  opacity: 1;
}

#drupal-off-canvas input[type="checkbox"] + label {
  margin: 0;
  padding: 0;
}

#drupal-off-canvas input[type="checkbox"] + label:before {
  width: 0;
  height: 0;
  border: none;
  background: none;
}

#drupal-off-canvas .button {
  min-width: initial;
}
