/**
* @file
* sites/mabel/_mabel.scss
*/
$mabel-site-name-height: 44px;

/* General MABEL styles */
.page-islandora {
  /* Hide WWU Search as it's confusing to have multiple searches */
  .western-header {
    .search {
      display: none;
    }

    .wrap {
      .site-name {
        text-transform: uppercase;
      }
    }
  }

  /* Reorder so left sidebar goes below main content area on 25/75 layouts */
  .layout--twocol-25-75 {
    .layout__region--top {
      order: 0;
    }
    .layout__region--first {
      @include breakpoint($medium) {
        order: 1;
      }
      order: 2;
    }
    .layout__region--second {
      @include breakpoint($medium) {
        order: 2;
      }
      order: 1;
    }
    .layout__region--bottom {
      order: 3;
    }
  }

  /* Style sidebar blocks */
  .layout__region--first {
    .panel-pane {
      margin-bottom: $standard-spacing;
      padding: $standard-spacing;
      background: $lightest-gray;
      font-size: $p-font-size--smaller;
    }

    a {
      border-bottom-color: transparent;

      &:hover,
      &:focus,
      &:active {
        color: $dark-green;
        border-bottom-color: $dark-green;
      }
    }

    h2 {
      margin-top: 0;
      padding-bottom: $standard-spacing;
      border-bottom-width: $border-width--thicker;
      font-family: $sans-serif;
      font-size: $p-font-size;
    }

    h3 {
      color: $dark-green;
      font-family: $sans-serif;
      font-size: $p-font-size--smaller;
    }

    input,
    select {
      border-width: $border-width;
    }

    input[type="submit"] {
      padding: $half-spacing $standard-spacing;
    }

    label {
      font-weight: normal;
    }
  }

  /* Date picker resets */
  .ui-datepicker {
    a {
      margin: initial;
    }
    select {
      display: inline;
      padding: initial;
      border: initial;
      font-weight: normal;
    }
  }

  &.not-front {
    .splash {
      @include breakpoint($medium) {
        grid-template-columns: ($standard-spacing + $logo-width + $standard-spacing) 1fr $standard-spacing;
        grid-template-rows: ($toggle-size + $standard-spacing) 1fr ($standard-spacing + ($toggle-size / 2));
      }
      @include breakpoint($large) {
        grid-template-areas:
        ". . ....... ."
        ". . content ."
        ". . ....... .";
        grid-template-columns: 1fr ($logo-width--larger + $double-spacing) ($max-content-width - $logo-width--larger - $standard-spacing) 1fr;
        grid-template-rows: ($toggle-size--larger + $standard-spacing) 1fr ($standard-spacing + ($toggle-size--larger / 2));
      }
      @include breakpoint(min-width 2420px) {
        grid-template-rows: ($toggle-size--larger + $double-spacing) 1fr ($standard-spacing + ($toggle-size--larger / 2));
      }
    }
  }

  .site-footer {
    #block-user-login {
      text-align: right;

      .form-submit {
        width: 100%;
        font-size: 1em;

        &:hover,
        &:focus,
        &:active {
          background: $darkest-blue;
        }
      }

      .item-list {
        display: none;
      }

      h2 {
        font-size: $h4-font-size;
      }
    }

    #edit-cas-login-redirection-message {
      margin: 0;
    }

    #edit-actions {
      margin-bottom: 0;
    }
  }
}
