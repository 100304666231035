/**
* @file
* 01-atoms/color/_color.scss
*/

/* These styles are only for the color swatches. See 00-utilities/color for color definitions. */
$swatch-min-width: 150px;
$swatch-max-width: 20%;
$swatch-height: 100px;

.sg-colors {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sg-colors li {
  flex: auto;
  padding: $half-spacing;
  margin: $half-spacing;
  min-width: $swatch-min-width;
  max-width: $swatch-max-width;
  border: $border-width solid $lightest-gray;
}

.sg-swatch {
  display: block;
  margin-bottom: $standard-spacing;
  height: $swatch-height;

  &.dark-blue {
    background: $dark-blue;
  }
  &.blue {
    background: $blue;
  }
  &.dark-green {
    background: $dark-green
  }
  &.light-green {
    background: $light-green;
  }
  &.darkest-blue {
    background: $darkest-blue ;
  }
  &.light-blue {
    background: $light-blue;
  }
  &.green {
    background: $green;
  }
  &.lightest-green {
    background: $lightest-green;
  }
  &.black {
    background: $black;
  }
  &.gray {
    background: $gray;
  }
  &.light-gray {
    background: $light-gray;
  }
  &.lightest-gray {
    background: $lightest-gray;
  }
  &.white {
    background: $white;
  }
  &.red {
    background: $red;
  }
  &.yellow {
    background: $yellow;
  }
}
