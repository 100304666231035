/**
 * @file
 * organisms/site-header/_site-header.scss
 */
$header-columns: $logo-width 1fr $toggle-size;
$header-columns--larger: $logo-width--larger 1fr $toggle-size--larger;
$header-rows: $toggle-size;
$header-rows--larger: $toggle-size--larger;

// gradients
$header-gradient-start: transparentize($white, 0.3);
$header-gradient-end: transparentize($white, 0.8);
$site-name-gradient-start: $dark-blue;
$site-name-gradient-end: transparentize($dark-blue, 0.2);

.western-header {
  @include breakpoint($medium) {
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: $small-screen-columns;
    -ms-grid-rows: auto;
    grid-template-columns: $small-screen-columns;
    grid-template-rows: auto;
  }
  @include breakpoint ($large) {
    -ms-grid-columns: $large-screen-columns;
    grid-template-columns: $large-screen-columns;
  }
  position: relative;
  z-index: 2;
  background: linear-gradient(to left, $header-gradient-start 50%, $header-gradient-end 100%);

  .wrap {
    @include breakpoint($medium) {
      position: relative;
      display: -ms-grid;
      display: grid;

      -ms-grid-column: 2;
      grid-column: 2 / span 2;
      -ms-grid-column-span: 1;
      -ms-grid-columns: $logo-width $standard-spacing 1fr $toggle-size;
      -ms-grid-row: 1;
      grid-row: 1 / span 1;
      -ms-grid-row-span: 1;
      -ms-grid-rows: $header-rows;
      grid-template-areas: "logo quick-links search lang_select";
      grid-template-columns: $header-columns .25fr;
      grid-template-rows: $header-rows;
    }
    @include breakpoint($large) {
      -ms-grid-columns: $logo-width--larger $standard-spacing 1fr $toggle-size--larger;
      -ms-grid-rows: $header-rows--larger;
      grid-template-columns: $header-columns--larger .25fr;
      grid-template-rows: $header-rows--larger;
    }

    .western-logo {
      color: $white;
      background-color: $dark-blue;

      grid-area: logo;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;

      .western-home-link {
        @include breakpoint($medium) {
          padding-right: 0;
        }
        display: flex;
        justify-content: center;
        margin: inherit;
        padding: 0;
        padding-right: $toggle-size--larger;
        color: $white;
        border: none;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
        }
      }
    }

    .site-name {
      @include breakpoint($medium) {
        display: block !important;
        border-top: $border-width--thinner solid $blue;
        border-bottom: none;
      }
      display: block;
      margin: 0;
      padding: $half-spacing $standard-spacing;
      text-align: center;
      text-decoration: none;
      text-transform: none;
      color: $white;
      border-top: $border-width--thinner solid $blue;
      border-bottom: $border-width--thinner solid $blue;
      background: linear-gradient(to right, $site-name-gradient-start 0%, $site-name-gradient-end 100%);
      font-family: $serif;
      font-size: $p-font-size--smaller;
      font-weight: $font-weight--regular;
      line-height: 1.5em;
    }

    .quick-links {
      @include breakpoint($medium) {
        display: block !important;
      }
      display: none;
      align-self: center;

      grid-area: quick-links;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      justify-self: end;
    }

    .toggle-menu {
      @include breakpoint($medium) {
        display: none;
      }
      position: absolute;
      top: 0;
      right: 0;

      &:hover,
      &:focus,
      &:active {
        background-color: $darkest-blue;
      }
    }

    .search {
      @include breakpoint($medium) {
        top: 0;
        right: 0;
      }
      align-self: center;

      grid-area: search;
      justify-self: end;
    }

    .lang_select {
      @include breakpoint($medium) {
        top:0;
        right: 0;
      }
      align-self: center;
      grid-area: lang_select;
      justify-self: end;
    }
  }
}
