/**
* @file
* organisms/wwu-footer/_wwu-footer.scss
*/

.wwu-footer {
  @include dark-blue-bg;
  @include breakpoint($small) {
    -ms-grid-columns: $standard-spacing 1fr 2fr $standard-spacing;
    -ms-grid-rows: $triple-spacing auto auto $triple-spacing;
    grid-template-areas: ". .... ..... ."
    ". logo info  ."
    ". logo icons ."
    ". .... ..... .";
    grid-template-columns: $standard-spacing 1fr 2fr $standard-spacing;
    grid-template-rows: $triple-spacing auto auto $triple-spacing;
    justify-items: end;
  }
  @include breakpoint($large) {
    -ms-grid-columns: 1fr ($max-content-width * (1 / 3)) ($max-content-width * (2 / 3)) 1fr;
    grid-template-columns: 1fr ($max-content-width * (1 / 3)) ($max-content-width * (2 / 3)) 1fr;
  }
  display: -ms-grid;
  display: grid;

  -ms-grid-columns: $small-screen-columns;
  -ms-grid-rows: $standard-spacing auto $standard-spacing auto $standard-spacing auto $standard-spacing;
  grid-template-areas: ". ....  ."
  ". logo  ."
  ". ....  ."
  ". info  ."
  ". ..... ."
  ". icons .";
  grid-template-columns: $small-screen-columns;
  grid-template-rows: repeat(3, $standard-spacing auto) $standard-spacing;
  justify-items: center;

  a {
    color: $white;
    border-bottom-color: $light-green;

    &:hover,
    &:focus,
    &:active {
      color: $light-blue;
      border-bottom-color: transparent;
    }
  }

  .logo {
    @include breakpoint($small) {
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 2;
    }

    grid-area: logo;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }

  .western-info {
    @include breakpoint($small) {
      text-align: right;

      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
    }
    text-align: center;

    grid-area: info;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
  }

  .social-media-links {
    @include breakpoint($small) {
      justify-content: flex-end;

      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      justify-self: end;
    }
    justify-content: center;

    grid-area: icons;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 6;
    -ms-grid-row-span: 1;

    .social-icon {
      margin: $standard-spacing 0 0 $standard-spacing;
    }
  }
}
