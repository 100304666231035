  /**
 * @file
 * 00-utilities/structure.scss
 */

/* Spacing */
$standard-spacing: 12px;
$quarter-spacing: $standard-spacing / 4;
$half-spacing: $standard-spacing / 2;
$double-spacing: $standard-spacing * 2;
$triple-spacing: $standard-spacing * 3;

/* Borders and Lines */
$border-width--thinner: 1px;
$border-width: 2px;
$border-width--thicker: 3px;
$min-border-width--thickest: 5px;
$max-border-width--thickest: 10px;
$border-width--thickest: calc(#{$min-border-width--thickest} + #{strip-unit($max-border-width--thickest - $min-border-width--thickest)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
$focus-outline: $border-width dotted;
$focus-outline--thicker: $border-width--thicker dotted;
$border-radius--round: 100px;
$border-radius--round-square: 10px;
$border-radius--square: 5px;

/* Content Area */
$max-content-width: 1300px;
$max-one-col-width: 1000px;

/* Breakpoints */
$small: 581px;
$medium: 841px;
$large: $max-content-width;

/* Grid */
$small-screen-columns: $standard-spacing 1fr $standard-spacing;
$large-screen-columns: 1fr $max-content-width 1fr;

/* Toggle/Menu Button Sizes */
$toggle-size: 58px;
$toggle-size--larger: 68px;

/* Class to break blocks out of the max content area and take up full view width */
.full-vw {
  margin-left:   calc(50% - 50vw);
  margin-right:  calc(50% - 50vw);
  padding-left:  calc(50vw - 50%);
  padding-right: calc(50vw - 50%);
}
