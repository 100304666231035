/*
* @file
* templates/layouts/onecol/_onecol.scss
*/

.layout--onecol .layout__region {
  @include breakpoint($max-one-col-width) {
    max-width: $max-one-col-width;
  }
  width: 100%;
  margin: auto;
}

.layout--onecol--full .layout__region {
  @include breakpoint($large) {
    max-width: $max-content-width;
    margin: auto;
  }
  width: 100%;
}

.layout--onecol--page-width {
  @include breakpoint($large) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  max-width: 100vw;
  margin-left: -$standard-spacing;
  margin-right: -$standard-spacing;

  .layout__region {
    max-width: none;
    margin: 0;
  }
}
