/**
* @file
*
*/

.date-stacked {
  margin: 0 1em 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 3em;
}
