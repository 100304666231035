/**
 * @file
 * 01-atoms/icons/social-media-icons/_social-media-icons.scss
 */
$icon-space: (($toggle-size - $icon-size) / 2) - 1;

.social-icon {
  display: block;
  width: $toggle-size;
  height: $toggle-size;
  transition: $standard-ease $button-transition-speed background-color;
  color: $white;
  background-color: $blue;

  svg {
    width: 100%;
    padding: (($toggle-size - $icon-size) / 2) - 1;
    transition: fill $standard-ease $button-transition-speed;

    fill: $white;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $dark-blue;
  }

  &:focus {
    @include breakpoint($medium) {
      outline-width: $border-width--thicker;
      outline-offset: $border-width--thicker;
    }
    outline: $focus-outline;
    outline-color: $dark-blue;
    outline-offset: $border-width;
  }

  &.medium {
    width: $icon-with-text-size--medium;
    height: $icon-with-text-size--medium;

    span {
      font-size: $p-font-size;
    }
  }

  &.large {
    width: $icon-with-text-size--large;
    height: $icon-with-text-size--large;

    span {
      font-size: $h4-font-size;
    }
  }
}

.social-media-links {
  @include breakpoint($medium) {
    max-width: none;
  }
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
  padding: 0;
  list-style: none;

  .social-icon {
    margin: $standard-spacing $standard-spacing 0 0;
  }
}

.my-western-links {
  .social-media-links {
    @include breakpoint($medium) {
      max-width: 600px;
      margin-top: -$triple-spacing;
      margin-left: 50%;
      transform: translatex(-50%);
    }
    @include breakpoint(1080px) {
      margin-top: -$triple-spacing * 3;
    }
    justify-content: center;
    width: 100%;
    max-width: none;
  }

  .social-icon {
    @include breakpoint($medium) {
      font-size: $min-base-font-size;
    }
    display: flex;
    flex-flow: column;
    align-items: center;
    width: initial;
    height: initial;
    padding: $standard-spacing;
    text-align: center;
    text-decoration: none;
    background: transparent;
    font-size: 14px;
    line-height: 1em;

    &:hover,
    &:active,
    &:focus {
      outline-color: $white;
      background: $blue;
    }

    svg {
      @include breakpoint($medium) {
        width: $toggle-size--larger;
        height: $toggle-size--larger;
      }
      width: $toggle-size;
      height: $toggle-size;
      margin-bottom: $half-spacing;
    }

    .icon-text {
      margin-bottom: $standard-spacing;
    }
  }
}
