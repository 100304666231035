/**
 * @file
 * molecules/forms/radio/_radio.scss
 * radio buttons inherit some styles from molecules/forms/checkbox/_checbox.scss
 */

input[type="radio"] {
  + label {
    &:before {
      border-radius: 50%;
    }
  }
  &:checked {
    + label {
      &:before {
        background-image: url("../images/forms/radio.png");
      }
    }
  }
}
