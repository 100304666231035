/**
* @file
* molecules/headline/_headline.scss
*/
$headline-gradient-start: transparentize($darkest-blue, 0.2);
$headline-gradient-end: transparentize($darkest-blue, 0.5);

.headline {
  h1:after {
    border: none;
  }

  /* Style Option 1: Box with semi-transparent overlay */
  &.box-headline {
    @include breakpoint($medium) {
      margin-top: 0;
      background: linear-gradient(to right, $headline-gradient-start 0%, $headline-gradient-end 100%);
    }
    align-self: start;
    max-width: $max-content-width * (2/3);
    margin-top: 25vh;
    padding: $double-spacing;
    color: $text-color-light;
    background-color: transparentize($darkest-blue, 0.3);

    -ms-grid-row-align: start;

    h1,
    h2 {
      margin-top: $standard-spacing;
      color: $text-color-light;
      border-bottom: none;
      font-family: $sans-serif;
    }

    .button {
      margin: $standard-spacing 0 0 0;
      color: $white;
      border-color: $white;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active {
        color: $blue;
        background-color: $white;
      }
    }
  }

  /* Style Option 2: Left aligned bottom bar */
  &.left-aligned-bar {
    @include breakpoint($small) {
      display: flex;
      flex-flow: row wrap;
      justify-content: end;
    }
    @include breakpoint($large) {
      max-width: 70vw;
      margin-left: calc(((100vw - #{$max-content-width}) / -2));
    }
    position: relative;
    align-self: end;
    width: 90vw;
    margin-top: 25vh;
    margin-bottom: $standard-spacing;
    margin-left: -$standard-spacing;
    padding: $double-spacing;
    color: $white;
    background: transparentize($blue, 0.1);

    h1,
    h2 {
      @include breakpoint($medium) {
        grid-column: initial;
      }
      position: relative;
      flex: 1 1;
      margin-top: 0;
      padding: 0;
      color: $white;
      border-bottom: none;
      font-family: $sans-serif;
      font-size: $h5-font-size;
      font-weight: $font-weight--regular;
    }

    .button {
      position: relative;
      align-self: end;
      margin-right: $standard-spacing;
      margin-left: $standard-spacing;
      border-color: $white;
      background-color: $blue;

      &:hover,
      &:focus,
      &:active {
        color: $blue;
        background: $white;
      }
    }
  }

  /* Style Option 3: Right aligned bottom bar */
  &.right-aligned-bar {
    @extend .left-aligned-bar;
    @include breakpoint($large) {
      margin-right: calc(((100vw - #{$max-content-width}) / -2));
    }
    margin-right: -$standard-spacing;
    margin-left: initial;

    justify-self: end;
  }

  /* Style Option 4: Simple centered */
  &.simple-centered {
    @include breakpoint($medium) {
      align-self: start;
      margin: 0;
    }
    align-self: center;
    margin: 10vh 0;
    text-align: center;

    justify-self: center;

    h1,
    h2 {
      @include breakpoint($large) {
        font-size: $h2-font-size--max--largest;
      }
      margin-top: 0;
      color: $white;
      border: none;
      text-shadow: 1px 1px 4px transparentize($darkest-blue, 0.1);
      font-family: $sans-serif;
      font-size: $h2-font-size--largest;
      font-weight: bold;
    }

    .tagline {
      @include breakpoint($large) {
        font-size: $h6-font-size--max--largest;
      }
      color: $white;
      text-shadow: 1px 1px 4px transparentize($darkest-blue, 0.1);
      font-size: $h6-font-size--largest;
    }

    .button {
      margin: $half-spacing;
      border-color: $white;
      background: transparentize($darkest-blue, 0.3);

      &:hover,
      &:focus,
      &:active {
        color: $blue;
        background: $white;
      }
    }
  }
}
