/**
* @file
* sites/gradschool/_gradschool.scss
*/

.graduate-school {
  .quick-links {
    li:first-child {
      display: none;
    }
  }
}
