/**
* @file
* molecules/forms/checkbox/_checkbox.scss
*/
$checkbox-size: 24px;
$checkbox-size--smaller: 20px;

.checkbox,
.radio {
  position: relative;
  margin-bottom: $half-spacing;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  width: $checkbox-size;
  height: $checkbox-size;
  margin: 0;
  padding: 0;
  opacity: 0;
  border: 0;
  outline: 0;

  + label {
    @include breakpoint($medium) {
      margin-left: $checkbox-size;
      line-height: $checkbox-size;
    }
    position: relative;
    display: inline-block;
    align-self: center;
    margin: 0;
    margin-left: $checkbox-size--smaller;
    padding-left: $half-spacing;
    font-weight: $font-weight--regular;
    line-height: $checkbox-size--smaller;

    &:before {
      @include breakpoint($medium) {
        width: $checkbox-size;
        height: $checkbox-size;
        background-size: $checkbox-size / 2;
      }
      position: absolute;
      left: 0;
      display: grid;
      align-items: center;
      box-sizing: border-box;
      width: $checkbox-size--smaller;
      height: $checkbox-size--smaller;
      content: "";
      transform: translatex(-100%);
      border-width: $border-width;
      border-style: solid;
      border-color: $light-gray;
      background-color: $lightest-gray;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: $checkbox-size--smaller / 2;

      justify-items: center;
    }
  }

  &:focus {
    + label {
      &:before {
        border-color: $light-blue;
        box-shadow: $focus-shadow;
        
        @media (-ms-high-contrast: active) {
          border-style: dotted;
        }
      }
    }
  }
}

input[type="checkbox"] {
  &:checked {
    + label {
      &:before {
        background-image: url("../images/forms/check.png");
      }
    }
  }
}
