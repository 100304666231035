/**
* @file
* molecules/text/page-title/_page-title.scss
*/
.page-title,
.pane-page-title h1 {
  @include breakpoint($large) {
    font-size: $h1-font-size--max--larger;
  }
  text-align: left;
  font-size: $h1-font-size--larger;
}

/* Page titles placed in splash area */
.splash {
  .block--page-title-block {
    @include breakpoint($medium) {
      display: block;
      padding-top: calc(#{$toggle-size} + #{$standard-spacing});
      padding-right: $standard-spacing;
      padding-bottom: calc((#{$toggle-size} / 2) + #{$standard-spacing});
      padding-left: $standard-spacing + $logo-width + $standard-spacing;
    }
    @include breakpoint($large) {
      padding-top: calc(#{$toggle-size--larger} + #{$standard-spacing});
      padding-right: calc((100vw - #{$max-content-width}) / 2 + #{$double-spacing});
      padding-bottom: calc((#{$toggle-size--larger} / 2) + #{$standard-spacing});
      padding-left: calc((100vw - #{$max-content-width}) / 2 + #{$logo-width--larger} + #{$double-spacing});
    }
    display: grid;
    justify-content: center;
    align-content: center;
    padding: $double-spacing;
    background-image: linear-gradient(215deg, transparentize($dark-blue, 0.5), transparentize($blue, 0.25));

    grid-column: 1/ span all !important;
    grid-row: 1 / span all !important;

    .page-title {
      @include breakpoint($large) {
        font-size: $h1-font-size--max;
      }
      margin: 0;
      padding: 0;
      text-align: left;
      text-transform: none;
      color: $white;
      font-family: $sans-serif;
      font-size: $h1-font-size--larger;

      &:after {
        border: none;
      }
    }
  }
}

.font-size--smaller {
  .page-title {
    @include breakpoint($large) {
      font-size: $h1-font-size--max--smaller;
    }
    font-size: $h1-font-size--smaller;
  }
}

.font-size--larger {
  .page-title {
    @include breakpoint($large) {
      font-size: $h1-font-size--max--larger;
    }
    font-size: $h1-font-size--larger;
  }
}
