/**
 * @file
 * 01-atoms/forms/_forms.scss
 */
$select-input-size: 28px;
$select-input-size--smaller: 24px;

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

legend,
label {
  display: block;
  margin-bottom: $standard-spacing;
  padding: 0;
  font-weight: $font-weight--semibold;

  &.required:after,
  &.form-required:after,
  .form-required:after {
    content: " *";
    color: $blue;
    font-weight: $font-weight--regular;
  }
}

input,
textarea,
select {
  @include breakpoint($medium) {
    border-width: $border-width--thicker;
  }
  display: block;
  box-sizing: border-box;
  max-width: 100%;
  margin-bottom: $standard-spacing;
  padding: $standard-spacing * 0.75;
  color: $black;
  border: solid $border-width $light-gray;
  border-radius: $border-radius--square;
  background-color: $lightest-gray;
  box-shadow: none;

  &:focus {
    outline: none;
    border-color: $light-blue;
    box-shadow: $focus-shadow;

    @media (-ms-high-contrast: active) {
      border-style: dotted;
    }
  }
  &:disabled {
    cursor: not-allowed;
    color: $gray;
    border-color: $gray;
    background-color: $light-gray;
  }
}

::placeholder {
  opacity: 1; /* Firefox */
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: transparentize($black, 0.1);
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: transparentize($black, 0.1);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: transparentize($black, 0.1);
}
