/**
* @file
* 00-molecules/navigation/menu/_menu.scss
*/

.menu {
  padding: 0;
  list-style: none;

  /* nested menus */
  .menu {
    margin: 0;

    li {
      border-left: none;
    }

    a {
      padding-left: $double-spacing;
      font-size: $p-font-size--smaller;
    }
  }
}
