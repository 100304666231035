/**
* @file
* 01-atoms/tables/_tables.scss
*/

table {
  width: 100%;
  margin: $standard-spacing 0;
  table-layout: fixed;
  word-wrap: break-word;
  border-collapse: collapse;
}
tbody {
  border: none;
}
tr {
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  &:nth-of-type(odd) {
    @include light-gray-bg;
  }
  &:nth-of-type(even) {
    @include lightest-gray-bg;
  }
  &.even,
  &.odd {
    border-bottom: none;
  }
}
th {
  @include light-gray-bg;
  padding: $half-spacing;
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  background-color: mix(black, $light-gray, 13%);
  font-size: $p-font-size--smaller;

  &[colspan],
  &[rowspan] {
    position: relative;
    color: $white;
    background-color: mix(black, $light-gray, 50%);

    &:after {
      position: absolute;
      z-index: 1;
      height: 100%;
      content: "";
      background: $white;
    }
  }

  &[colspan] {
    text-align: center;
    &:after {
      @include breakpoint($medium) {
        right: -$border-width--thicker - 1px;
        width: $border-width--thicker;
      }
      top: 0;
      right: -$border-width - 1px;
      width: $border-width;
    }
  }

  &[rowspan] {
    &:after {
      @include breakpoint($medium) {
        bottom: -$border-width--thicker - 1px;
        height: $border-width--thicker;
      }
      bottom: -$border-width - 1px;
      left: 0;
      width: 100%;
      height: $border-width;
    }
  }
}

td {
  @include breakpoint($medium) {
    border-width: $border-width--thicker;
  }
  padding: $half-spacing;
  border-top: none;
  border-bottom: none;
  border-left: $border-width solid $lightest-gray;
  font-size: $p-font-size--smaller;

  &:first-of-type {
    border-left: none;
  }

  &:last-of-type {
    border-right: $border-width solid $lightest-gray;
  }
}

tr,
td,
th {
  vertical-align: middle;
}

caption {
  padding-bottom: $standard-spacing;
  font-size: $p-font-size--smaller;
  font-weight: $font-weight--bold;
}
