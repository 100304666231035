/**
* @file
* 01-atoms/icons/icons-with-text/_icons-with-text.scss
*/
$icon-with-text-size--small: 80px;
$icon-with-text-size--medium: 140px;
$icon-with-text-size--large: 180px;

.icon-with-text {
  display: inline-grid;
  text-decoration: none;
  width: $icon-with-text-size--small;
  height: $icon-with-text-size--small;
  justify-content: center;
  align-content: center;
  text-align: center;

  .icon-text {
    display: block;
    font-size: $min-base-font-size;
    margin-top: $half-spacing;
  }

  &.medium {
    width: $icon-with-text-size--medium;
    height: $icon-with-text-size--medium;

    .material-icons {
      font-size: $icon-size * 2;
    }

    .icon-text {
      font-size: $p-font-size;
    }

  }

  &.large {
    width: $icon-with-text-size--large;
    height: $icon-with-text-size--large;

    .material-icons {
      font-size: $icon-size--large * 2;
    }

    .icon-text {
      font-size: $h4-font-size;
    }
  }
}
