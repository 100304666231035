/**
* @file
* 02-molecules/content/search/_search.scss
*/

.search {
  @include breakpoint($medium) {
    right: inherit;
    left: inherit;
  }
  position: absolute;
  z-index: 0;
  top: $toggle-size--larger;
  right: 0;
  display: flex;
  justify-content: end;

  .toggle {
    @include breakpoint($medium) {
      &:hover,
      &:focus,
      &:active {
        background-color: $dark-blue;
      }
    }
    @include breakpoint($large) {
      min-width: $toggle-size--larger;
    }
    min-width: $toggle-size;
    &:hover,
    &:focus,
    &:active {
      background-color: $darkest-blue;
    }
  }

  .toggle-search {
    order: 2;
  }

  .search-area {
    display: flex;
    order: 1;

    .search-box {
      input {
        @include breakpoint($medium) {
          height: $toggle-size;
        }
        @include breakpoint($large) {
          height: $toggle-size--larger;
          padding: $standard-spacing;
        }
        max-width: calc(100vw - (#{$toggle-size--larger} * 2));
        height: $toggle-size--larger;
        margin: 0;
        padding: $half-spacing;
        border-color: $blue;
      }
    }

    .search-label {
      margin: 0;
      font-size: 0;
    }
  }
}
