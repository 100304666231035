/**
  * @file
  * 01-atoms/buttons/toggles/_toggles.scss
  */
.toggle {
  @include breakpoint($medium) {
    width: $toggle-size;
    height: $toggle-size;
  }
  @include breakpoint($large) {
    width: $toggle-size--larger;
    height: $toggle-size--larger;
  }
  width: $toggle-size--larger;
  height: $toggle-size--larger;
  padding: 0;
  color: $white;
  border: 0;
  border-radius: 0;
  background: $blue;
  box-shadow: none;

  &:active,
  &:hover,
  &:focus {
    transform: none;
    background: $dark-blue;
    box-shadow: none;
  }

  .material-icons {
    top: inherit;
  }
}
