/**
 * @file
 * molecules/ribbon/_ribbon.scss
 */
$ribbon-width: 300px;
$ribbon-tail-height: 50px;

.ribbon {
  transition: $medium-transition-speed $standard-ease;
  position: relative;
  display: block;
  width: $ribbon-width;
  margin-bottom: $ribbon-tail-height;
  padding: $triple-spacing;
  text-align: center;
  text-decoration: none;
  letter-spacing: ($half-spacing) / 2;
  background: mix($white, $light-gray, 50%);


  &:before,
  &:after {
    transition: $medium-transition-speed $standard-ease;
    position: absolute;
    bottom: -$ribbon-tail-height;
    display: block;
    width: 0;
    height: 0;
    content: "";
    transition-property: border-color;
    border-top: $ribbon-tail-height solid mix($white, $light-gray, 50%);
  }

  &:before {
    left: 0;
    border-right: ($ribbon-width / 2) solid transparent;
  }

  &:after {
    right: 0;
    border-left: ($ribbon-width / 2) solid transparent;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background-color: $light-gray;
  }

  &:hover:after,
  &:focus:after,
  &:active:after,
  &:hover:before,
  &:focus:before,
  &:active:before {
    border-top-color: $light-gray;
  }

  .above {
    display: block;
    color: $link-blue;
    border-bottom: $dark-blue $border-width solid;
    font-size: 32px;
  }

  .below {
    color: $dark-blue;
    font-size: 22px;
  }
}
