/**
 * @file
 * molecules/media/splash/_splash-image.scss
 */

.splash {
  img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
