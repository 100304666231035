/**
* @file
* 01-atoms/buttons/_main-nav-buttons.scss
*/
$max-button-width: 250px;

.nav--main,
#block-system-main-menu {
  & > .menu,
  .ultimenu--horizontal,
  .ultimenu-main-menu {
    background-color: initial;

    & > li {
      @include breakpoint($medium) {
        width: inherit;
        border-bottom: none;
      }
      width: 100%;
      border: none;
      border-bottom: 1px solid $darkest-blue;

      /* Keep borders even */
      &:first-of-type {
        a {
          border-left-width: ($half-spacing / 2);
        }
      }

      & > a {
        @include breakpoint($medium) {
          display: flex;
          align-items: center;
          max-width: $max-button-width;
          padding: 0 ($standard-spacing * 1.5);
          text-align: center;
        }
        @include breakpoint($large) {
          height: $toggle-size--larger;
        }
        position: relative;
        display: grid;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: $toggle-size;
        margin: 0;
        padding: $standard-spacing;
        text-align: left;
        white-space: normal;
        text-decoration: none;
        color: $white;
        outline: ($half-spacing / 2) solid transparent;
        background-color: $dark-blue;
        font-family: $condensed;
        line-height: 1em;

        &:hover,
        &:focus,
        &:active {
          color: $black;
          outline: none;
          background-color: $light-green;
        }

        &:focus {
          outline: ($half-spacing / 2) dotted $black;
          outline-offset: -($half-spacing / 2);
        }

        &:after {
          @include breakpoint($medium) {
            display: none;
          }
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: $toggle-size;
          height: 100%;
          content: "";
          transform: translatex(100%);
        }

        &.active {
          color: $dark-blue;
          background-color: $light-green;
        }
      }
    }
  }
}
