/**
 * @file
 * templates/layouts/twocol_66_34/_layout--twocol-66-34.scss
 */

.layout--twocol-66-34 {
  display: flex;
  flex-wrap: wrap;
}

.layout--twocol-66-34 > .layout__region {
  flex: 0 1 100%;
}

.layout--twocol-66-34 > .layout__region--first {
  @include breakpoint($medium) {
    flex: 0 1 66%;
    max-width: 66%;
    padding-right: $standard-spacing;
  }
}

.layout--twocol-66-34 > .layout__region--second {
  @include font-size--smaller;
  @include breakpoint($medium) {
    flex: 0 1 34%;
    max-width: 34%;
    padding-left: $standard-spacing;
  }
}
