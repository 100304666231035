/**
* @file
* organisms/statistics-collection/statistics-collection.scss
*/

.statistics-collection--vertical {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
