/**
* @file
* organisms/cards/_cards.scss
*/
$spotlight-height--medium: 200px;
$spotlight-height--large: 300px;

.spotlight {
  @include breakpoint($large) {
    height: $spotlight-height--large;
  }
  height: $spotlight-height--medium;
}

/* Column card layouts, grid style */
/* All grid column layouts collapse down to a single column on small screens*/
.card-2col,
.card-3col,
.card-4col,
.card-2col--view .view-content,
.card-3col--view .view-content,
.card-4col--view .view-content {
  display: grid;

  grid-column-gap: $standard-spacing;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.card-2col,
.card-2col--view .view-content {
  @include breakpoint($small) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}

.card-3col,
.card-3col--view .view-content {
  @include breakpoint($small) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

.card-4col,
.card-4col--view .view-content {
  @include breakpoint($small) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

/* Column card layouts, flex style */
/* All flex column layouts collapse down to a single column on small screens*/
.card-2col--flex,
.card-3col--flex,
.card-4col--flex {
  display: flex;
  flex-flow: row wrap;

  .card,
  .block--card {
    flex: 0 0 100%;
  }
}

.card-2col--flex {
  .card,
  .block--card {
    @include breakpoint($small) {
      flex: 0 0 calc(50% - #{$half-spacing});
      margin-right: $half-spacing;
    }
  }
}

.card-3col--flex {
  .card,
  .block--card {
    @include breakpoint($small) {
      flex: 0 0 calc(33.33% - #{$half-spacing});
      margin-right: $half-spacing;
    }
  }
}

.card-4col--flex {
  .card,
  .block--card {
    @include breakpoint($small) {
      flex: 0 0 calc(25% - #{$half-spacing});
      margin-right: $half-spacing;
    }
  }
}

/* For layouts using --flex, use this class to break out of the columns */
.card--flex--section-divider {
  flex: 0 0 100%;
}

/* Vertical Card List */
.card-list {
  .card,
  .block--card {
    display: -ms-grid;
    display: grid;

    grid-column-gap: $standard-spacing;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: auto 1fr;
    grid-template-areas: "image taxonomy"
    "image title"
    "image body";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr;

    > div {
      @include breakpoint ($medium) {
        padding: $triple-spacing;
        padding-left: $double-spacing;
      }
      padding: $standard-spacing;
      padding-left: $half-spacing;
    }

    .image {
      margin-bottom: 0;
      padding: 0;

      grid-area: image;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;

      > div {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }

    .taxonomy-terms {
      margin-bottom: 0;
      padding-bottom: 0;

      grid-area: taxonomy;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
    }

    .title {
      margin-top: 0;
      padding-bottom: 0;

      grid-area: title;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
    }

    .body {
      grid-area: body;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
    }
  }
}

/* Additional styles for card blocks used in "flex layout" sections*/
.layout--flex {
  .layout__region {
    .block--card {
      flex: 1;
      margin: 0 $half-spacing;
    }
  }
}

/* Internet exploreer */
@media screen and (min-width:0\0) {
  .card-2col,
  .card-2col--view,
  .card-2col--flex {
    display: initial;

    .card,
    .block--card {
      float: left;
      width: 50%;
    }
  }

  .card-3col,
  .card-3col--view,
  .card-3col--flex {
    display: initial;

    .card,
    .block--card {
      float: left;
      width: 33%;
    }
  }

  .card-4col,
  .card-4col--view,
  .card-4col--flex {
    display: initial;

    .card,
    .block--card {
      float: left;
      width: 25%;
    }
  }

  .card-2col--view .view-content,
  .card-3col--view .view-content,
  .card-4col--view .view-content {
    display: initial;
  }

  .card--flex--section-divider {
    float: left;
    width: 100%;
  }
}
