/**
 * @file
 * helpers/_size.scss
 * Helper classes to adjust spacing
 */

.min-height--25vh {
  min-height: 25vh;
}

.min-height--50vh {
  min-height: 50vh;
}

.min-height--75vh {
  min-height: 75vh;
}

.min-height--100vh {
  min-height: 100vh;
}

.max-width--content {
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
}
