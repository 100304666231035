/**
 * @file
 * helpers/_space.scss
 * Helper classes to adjust spacing
 */

.standard-margin {
  margin: $standard-spacing;
}

.double-margin {
  margin: $double-spacing;
}

.no-margin {
  margin: 0;
}

.no-margin--top {
  margin-top: 0;
}

.no-margin--right {
  margin-right: 0;
}

.no-margin--bottom {
  margin-bottom: 0;
}

.no-margin--left {
  margin-left: 0;
}

.standard-padding {
  padding: $standard-spacing;
}

.double-padding {
  padding: $double-spacing;
}

.no-padding {
  padding: 0;
}

.no-padding--top {
  padding-top: 0;
}

.no-padding--right {
  padding-right: 0;
}

.no-padding--bottom {
  padding-bottom: 0;
}

.no-padding--left {
  padding-left: 0;
}

/* space creating blocks */
.vertical-space--1x {
  @include breakpoint($medium) {
    height: $standard-spacing;
  }
}

.vertical-space--2x {
  @include breakpoint($medium) {
    height: $standard-spacing * 2;
  }
}

.vertical-space--3x {
  @include breakpoint($medium) {
    height: $standard-spacing * 3;
  }
}

.vertical-space--4x {
  @include breakpoint($medium) {
    height: $standard-spacing * 4;
  }
}

.vertical-space--5x {
  @include breakpoint($medium) {
    height: $standard-spacing * 5;
  }
}

.vertical-space--6x {
  @include breakpoint($medium) {
    height: $standard-spacing * 6;
  }
}

.vertical-space--7x {
  @include breakpoint($medium) {
    height: $standard-spacing * 7;
  }
}

.vertical-space--8x {
  @include breakpoint($medium) {
    height: $standard-spacing * 8;
  }
}

.close-menu-gap {
  margin-top: -$standard-spacing;

  @include breakpoint($medium) {
    margin-top: -$toggle-size;
  }
  @include breakpoint($large) {
    margin-top: -$toggle-size--larger;
  }
}
