/**
 * @file
 * molecules/forms/form/_form.scss
 */

.form {
  .form-item {
    margin-bottom: $half-spacing;
  }
  .description {
    color: $gray;
  }
}
