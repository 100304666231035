/**
* @file
* sites/mabel/pages/_front.scss
*/
.islandora-front-page {
  .page-content {
    h2 {
      padding-bottom: 0;
      text-align: center;
      border-bottom: none;
      font-size: $h1-font-size;
    }
  }

  .layout__region--top {
    padding-top: 0;
  }

  .browse-by-icons {
    @include breakpoint($medium) {
      max-width: 80%;
    }
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: $double-spacing auto;


    .icon-with-text {
      padding: $standard-spacing;
      border: solid $border-width transparent;

      .icon-text {
        font-size: $h5-font-size;
      }

      .material-icons {
        color: $dark-blue;
        font-size: $h1-font-size;
      }

      &:hover,
      &:focus-within {
        transform: translate(-2px, -2px);
        border-color: $dark-blue;
        outline: none;
        background: transparent;
      }
    }
  }
}

.card-4col--mabel-view .view-header {
  @include breakpoint($small) {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  display: -ms-grid;
  display: grid;

  grid-column-gap: $standard-spacing;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
